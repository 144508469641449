import React, { useCallback } from 'react';
import { Link } from 'components';
import styled from 'styled-components';
import { Footer, InputsContainer, InputContainer, Input, Label, SubmitButton, ErrorMessage } from 'components/Modal';
import { RECAPTCHA_SITEKEY } from 'settings';
import { useFormHandlers } from './LoginForm';
import ReCAPTCHA from 'react-google-recaptcha';

export const Form = styled.form``;

const values = { username: '', password: '', captcha: null, email: '' };
const fields = Object.keys(values);
const allFieldsFilled = (form) => fields.every(f => !!form[f].value || form[f].value === false);

const SignUpForm = ({ onSubmit, openLoginModal }) => {
  const {
    form,
    handleSubmit,
    isFieldValid,
    isSubmitting,
  } = useFormHandlers({
    onSubmit,
    fields,
    values,
  });

  const captchaRef = React.useRef();

  const onChangeReCaptcha = useCallback((result) => {
    form.captcha.handler(result);
  }, [form.captcha]);

  React.useEffect(() => {
    captchaRef.current && captchaRef.current.reset();
  }, [isSubmitting]);

  return (
    <React.Fragment>
      <Form className="modal-form" onSubmit={handleSubmit}>
        <InputsContainer>
          <InputContainer>
            <Label>Username</Label>
            <Input
              placeholder="Username"
              className="Input"
              type="text"
              value={form.username.value}
              required
              onChange={(e) => form.username.handler(e.target.value)}
              invalid={!isFieldValid('username')}
            />
            {!isFieldValid('username') ? <ErrorMessage>{form.username.error}</ErrorMessage> : null}
          </InputContainer>
          <InputContainer>
            <Label>Your E-Mail Address</Label>
            <Input
              placeholder="Your E-Mail Address"
              className="Input"
              type="email"
              value={form.email.value}
              required
              onChange={(e) => form.email.handler(e.target.value)}
              invalid={!isFieldValid('email')}
            />
            {(!isFieldValid('email')) ? <ErrorMessage>{form.email.error}</ErrorMessage> : null}
          </InputContainer>
          <InputContainer>
            <Label>Password</Label>
            <Input
              placeholder="Password"
              className="Input"
              type="password"
              value={form.password.value}
              required
              onChange={(e) => form.password.handler(e.target.value)}
              invalid={!isFieldValid('password')}
            />
            {!isFieldValid('password') ? <ErrorMessage>{form.password.error}</ErrorMessage> : null}
          </InputContainer>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={RECAPTCHA_SITEKEY}
            onChange={onChangeReCaptcha}
          />
          {(!isFieldValid('captcha')) ? <ErrorMessage>{form.captcha.error}</ErrorMessage> : null}
        </InputsContainer>
        <Footer>
          <div>
            <SubmitButton className="modal-btn" type="submit" disabled={isSubmitting || !allFieldsFilled(form)}>Join edcat</SubmitButton>
          </div>
          <p>
            By creating your edcat account you agree to our
            {' '}
            <Link to="/info/terms">Terms of Use</Link>
            {' '}
            and
            {' '}
            <Link to="/info/privacy">Privacy Policy.</Link>
          </p>
          <p>
            You already have an account? <Link to="" onClick={openLoginModal}>Log in!</Link>
          </p>
        </Footer>
      </Form>
    </React.Fragment>
  )
}

export default SignUpForm
