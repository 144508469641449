/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";
import { Button } from "components";
import styled from "styled-components";
import { CloseIconSVG } from "components/styled";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import useApiCall from "hooks/useApiCall";
import Edcat from "services/edcat";
import { usePrevious } from "utils/helpers";

import ItemList from "../components/ItemList";
import { FadeIn } from "components";
import { uniqBy } from "lodash";
const BUTTON_HEADER = {
  items_for_sale: { key: "nothing", value: "items for sale" },
  contributions: { key: "contributions", value: "contributions" },
  stories: { key: "stories", value: "stories" },
  in_collection: { key: "collections", value: "items in collection" },
  in_want_list: { key: "wantlist", value: "items on the wantlist" },
};
const ShopTitle = styled.div`
  margin-bottom: 16px;
  margin-top: 0px;
  text-align: unset;
  font-size: 24px;
`;
const ShopLocation = styled.div`
  font-size: 14px;
`;
export const ItemCloseIcon = styled(CloseIconSVG("black"))`
  height: 23px;
  margin-left: 7px;
  margin-right: -5px;
  margin-top: -8px;
  margin-bottom: -8px;
  filter: invert(1) !important;
`;
const Shop = () => {
  const location = useLocation();
  const { history } = useReactRouter();
  const userData = location.state.userData;

  const [selectedFilter, setSelectedFilter] = useState(null);
  const goBack = () => {
    history.push({
      pathname: `/users/${userData.username}/`,
    });
  };

  const selectFilter = (key) => {
    setLoading(true);
    setPage(1);
    if (key === "nothing") {
      goBack();
    }
    if (selectedFilter === key) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(key);
    }
  };

  const [page, setPage] = useState(1);
  const [loadedItems, setLoadedItems] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [loadingReally, setLoading] = useState(true); //remove
  const { data, component, loading } = useApiCall(
    () =>
      Edcat.shopItems({
        username: userData.username,
        filter: selectedFilter,
        page,
      }),
    [page, selectedFilter]
  );
  const prev = usePrevious({ loading });
  useEffect(() => {
    if (!loading && isInitialLoading) setIsInitialLoading(false);
    if (loading || !data) return;
    data.results && data.results.length && page === 1
      ? setLoadedItems(data.results)
      : setLoadedItems((loadedItems) =>
          uniqBy([...loadedItems, ...data.results], "slug")
        );
  }, [data, isInitialLoading, loading, page]);
  if (
    (!selectedFilter && loadingReally) ||
    (prev.loading && !loading && data && loadingReally)
  )
    setLoading(false); //removes flicker when select and deselect one keyword

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: !!(data || {}).next,
    onLoadMore: () => setPage(page + 1),
    scrollContainer: "window",
    threshold: 500, //px
  });
  if (isInitialLoading) return null;
  return (
    <div className="User Shop" ref={infiniteRef}>
      <Button className="go-back" onClick={goBack}>
        <svg
          width="18px"
          fill="#fff"
          height="18px"
          viewBox="0 0 370.814 370.814"
        >
          <polygon points="292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401" />
        </svg>
        Back to the Shop
      </Button>
      <div className="user-header-container">
        <div className="user-page-title-container">
          <img src={userData.image} />
          <div>
            <ShopTitle>{userData.name}</ShopTitle>
            <ShopLocation>{userData.location}</ShopLocation>
            <br />
            <div className="info-container">
              {userData.website && (
                <a href={userData.website}>
                  <svg
                    width="22px"
                    height="22px"
                    fill="#96989E"
                    viewBox="0 0 50 50"
                  >
                    <path d="M38.288 10.297l1.414 1.415-14.99 14.99-1.414-1.414z" />
                    <path d="M40 20h-2v-8h-8v-2h10z" />
                    <path d="M35 38H15c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h11v2H15c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V24h2v11c0 1.7-1.3 3-3 3z" />
                  </svg>
                  {userData.website}
                </a>
              )}
              {userData.instagram && (
                <a href={userData.instagram}>
                  <svg
                    width="16px"
                    height="16px"
                    fill="#96989E"
                    viewBox="0 0 2500 2500"
                  >
                    <path d="M833.4,1250c0-230.11,186.49-416.7,416.6-416.7s416.7,186.59,416.7,416.7-186.59,416.7-416.7,416.7S833.4,1480.11,833.4,1250m-225.26,0c0,354.5,287.36,641.86,641.86,641.86S1891.86,1604.5,1891.86,1250,1604.5,608.14,1250,608.14,608.14,895.5,608.14,1250M1767.27,582.69a150,150,0,1,0,150.06-149.94h-0.06a150.07,150.07,0,0,0-150,149.94M745,2267.47c-121.87-5.55-188.11-25.85-232.13-43-58.36-22.72-100-49.78-143.78-93.5s-70.88-85.32-93.5-143.68c-17.16-44-37.46-110.26-43-232.13-6.06-131.76-7.27-171.34-7.27-505.15s1.31-373.28,7.27-505.15c5.55-121.87,26-188,43-232.13,22.72-58.36,49.78-100,93.5-143.78s85.32-70.88,143.78-93.5c44-17.16,110.26-37.46,232.13-43,131.76-6.06,171.34-7.27,505-7.27s373.28,1.31,505.15,7.27c121.87,5.55,188,26,232.13,43,58.36,22.62,100,49.78,143.78,93.5s70.78,85.42,93.5,143.78c17.16,44,37.46,110.26,43,232.13,6.06,131.87,7.27,171.34,7.27,505.15s-1.21,373.28-7.27,505.15c-5.55,121.87-25.95,188.11-43,232.13-22.72,58.36-49.78,100-93.5,143.68s-85.42,70.78-143.78,93.5c-44,17.16-110.26,37.46-232.13,43-131.76,6.06-171.34,7.27-505.15,7.27s-373.28-1.21-505-7.27M734.65,7.57c-133.07,6.06-224,27.16-303.41,58.06C349,97.54,279.38,140.35,209.81,209.81S97.54,349,65.63,431.24c-30.9,79.46-52,170.34-58.06,303.41C1.41,867.93,0,910.54,0,1250s1.41,382.07,7.57,515.35c6.06,133.08,27.16,223.95,58.06,303.41,31.91,82.19,74.62,152,144.18,221.43S349,2402.37,431.24,2434.37c79.56,30.9,170.34,52,303.41,58.06C868,2498.49,910.54,2500,1250,2500s382.07-1.41,515.35-7.57c133.08-6.06,223.95-27.16,303.41-58.06,82.19-32,151.86-74.72,221.43-144.18s112.18-139.24,144.18-221.43c30.9-79.46,52.1-170.34,58.06-303.41,6.06-133.38,7.47-175.89,7.47-515.35s-1.41-382.07-7.47-515.35c-6.06-133.08-27.16-224-58.06-303.41-32-82.19-74.72-151.86-144.18-221.43S2150.95,97.54,2068.86,65.63c-79.56-30.9-170.44-52.1-303.41-58.06C1632.17,1.51,1589.56,0,1250.1,0S868,1.41,734.65,7.57" />
                    <path d="M833.4,1250c0-230.11,186.49-416.7,416.6-416.7s416.7,186.59,416.7,416.7-186.59,416.7-416.7,416.7S833.4,1480.11,833.4,1250m-225.26,0c0,354.5,287.36,641.86,641.86,641.86S1891.86,1604.5,1891.86,1250,1604.5,608.14,1250,608.14,608.14,895.5,608.14,1250M1767.27,582.69a150,150,0,1,0,150.06-149.94h-0.06a150.07,150.07,0,0,0-150,149.94M745,2267.47c-121.87-5.55-188.11-25.85-232.13-43-58.36-22.72-100-49.78-143.78-93.5s-70.88-85.32-93.5-143.68c-17.16-44-37.46-110.26-43-232.13-6.06-131.76-7.27-171.34-7.27-505.15s1.31-373.28,7.27-505.15c5.55-121.87,26-188,43-232.13,22.72-58.36,49.78-100,93.5-143.78s85.32-70.88,143.78-93.5c44-17.16,110.26-37.46,232.13-43,131.76-6.06,171.34-7.27,505-7.27s373.28,1.31,505.15,7.27c121.87,5.55,188,26,232.13,43,58.36,22.62,100,49.78,143.78,93.5s70.78,85.42,93.5,143.78c17.16,44,37.46,110.26,43,232.13,6.06,131.87,7.27,171.34,7.27,505.15s-1.21,373.28-7.27,505.15c-5.55,121.87-25.95,188.11-43,232.13-22.72,58.36-49.78,100-93.5,143.68s-85.42,70.78-143.78,93.5c-44,17.16-110.26,37.46-232.13,43-131.76,6.06-171.34,7.27-505.15,7.27s-373.28-1.21-505-7.27M734.65,7.57c-133.07,6.06-224,27.16-303.41,58.06C349,97.54,279.38,140.35,209.81,209.81S97.54,349,65.63,431.24c-30.9,79.46-52,170.34-58.06,303.41C1.41,867.93,0,910.54,0,1250s1.41,382.07,7.57,515.35c6.06,133.08,27.16,223.95,58.06,303.41,31.91,82.19,74.62,152,144.18,221.43S349,2402.37,431.24,2434.37c79.56,30.9,170.34,52,303.41,58.06C868,2498.49,910.54,2500,1250,2500s382.07-1.41,515.35-7.57c133.08-6.06,223.95-27.16,303.41-58.06,82.19-32,151.86-74.72,221.43-144.18s112.18-139.24,144.18-221.43c30.9-79.46,52.1-170.34,58.06-303.41,6.06-133.38,7.47-175.89,7.47-515.35s-1.41-382.07-7.47-515.35c-6.06-133.08-27.16-224-58.06-303.41-32-82.19-74.72-151.86-144.18-221.43S2150.95,97.54,2068.86,65.63c-79.56-30.9-170.44-52.1-303.41-58.06C1632.17,1.51,1589.56,0,1250.1,0S868,1.41,734.65,7.57" />
                  </svg>
                  {userData.instagram}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="item-filter-container">
        <div className="header-filter">
          {Object.keys(userData.items_numbers).map((item) => (
            <Button
              onClick={() => selectFilter(BUTTON_HEADER[item].key)}
              key={item}
              className={
                selectedFilter === BUTTON_HEADER[item].key
                  ? "hovered-button"
                  : ""
              }
            >
              <div className="filter-button-content">
                <div className="filter-name">
                  {userData.items_numbers[item]} {BUTTON_HEADER[item].value}
                </div>
              </div>
              {selectedFilter === BUTTON_HEADER[item].key && <ItemCloseIcon />}
            </Button>
          ))}
        </div>
      </div>
      {selectedFilter ? (
        <>
          {loadedItems && !loadingReally ? (
            <>
              <FadeIn duration={700}>
                <ItemList
                  featuredItem={false}
                  itemClassName={
                    selectedFilter === "stories"
                      ? "storyItemCard"
                      : "shopItemCard"
                  }
                  externalUrl={selectedFilter === "stories" ? true : false}
                  listType={"relatedStories"}
                  items={loadedItems || []}
                />
              </FadeIn>
            </>
          ) : null}
          {component && loading && !loadingReally && (
            <Button className="loadmore">Load More ...</Button>
          )}
        </>
      ) : (
        <div className="info-details">
          <div>
            <h2>About</h2>
            <p>{userData.information}</p>
            <h2>Shipping Costs</h2>
            <div className="shipping-container">
              {userData.shipping_costs.map((shipping, i) => (
                <div className="shipping-item" key={i}>
                  <h4>{shipping.name}</h4>
                  {shipping.options.map((op, j) => (
                    <div className="cost" key={j}>
                      <span>{op.country}</span>
                      <span className="price">{`EUR ${op.unregistered}`}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <p
              className="orderTerms"
              dangerouslySetInnerHTML={{
                __html: userData.general_order_terms.replace(/\n/g, "<br />"),
              }}
            ></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shop;
