import React from 'react';
import { HamburgerButton } from 'react-hamburger-button';

const Hamburger = (props)=>{
  const { isMobileMenuOpen,
          handleClickHamburger } = props;  

  return (
    <HamburgerButton
      open={isMobileMenuOpen}
      onClick={handleClickHamburger}
      width={24}
      height={16}
      strokeWidth={1}
      color='black'
      animationDuration={0.1}
    />    
  )
} 

export default Hamburger;