import React, { Component } from 'react';
import Marketplace from './Marketplace';

export default class MarketplaceContainer extends Component {
  render() {
    return (
      <Marketplace {...this.props} />
    )
  }
}
