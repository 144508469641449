import React from 'react';
import AccountUI from './AccountUI';
import { useStore } from 'store';
import { Modal } from 'components';
import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import ConfirmEmail from './components/ConfirmEmail';
import { useApiHandlers, useModals, useUserMenu } from './hooks';
import { LOGIN_MODAL, SIGN_UP_MODAL, CONFIRM_EMAIL_MODAL } from './constants';

const AccountUIContainer = (props) => {
  const {
    openedModal,
    openLoginModal,
    openSignUpModal,
    openConfirmEmailModal,
    wrapClose,
    closeModal,
  } = useModals();

  const { login, logout, signUp } = useApiHandlers();

  const [user] = useStore('user');
  const { username } = user || {};
  const isLoggedIn = !!user;

  const links = useUserMenu({ isLoggedIn, openLoginModal, openSignUpModal, logout });
  const handleSignUp = React.useCallback(async (values) => {
    await signUp(values);
    openConfirmEmailModal();
  }, [openConfirmEmailModal, signUp]);
  return (
    <React.Fragment>
      <AccountUI
        isLoggedIn={isLoggedIn}
        username={username}
        links={links}
        {...props}
      />
      <Modal
        isOpen={openedModal === LOGIN_MODAL}
        onClose={closeModal}
        title="Log in to edcat"
      >
        <LoginForm
          onSubmit={wrapClose(login)}
          openSignUpModal={openSignUpModal}
        />
      </Modal>
      <Modal
        isOpen={openedModal === SIGN_UP_MODAL}
        onClose={closeModal}
        title="Create an Account"
      >
        <SignUpForm
          onSubmit={handleSignUp}
          openLoginModal={openLoginModal}
        />
      </Modal>
      <Modal
        isOpen={openedModal === CONFIRM_EMAIL_MODAL}
        onClose={closeModal}
        title="Sign up"
        titleCentered
      >
        <ConfirmEmail />
      </Modal>
    </React.Fragment>
  )
}


export default AccountUIContainer;