import React, { Component } from 'react';
import Items from './Items';

export default class ItemsContainer extends Component {
  render() {
    return (
      <Items {...this.props} />
    )
  }
}
