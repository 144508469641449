import React, { useState } from 'react';
import Header from './Header';
import { withRouter } from 'react-router';

// import _ from 'lodash';
// import queryString from 'query-string';

const HeaderContainer = (props) => {
  // TODO: fix
  // const currentQueryParams = queryString.parse(_.get(props, 'location.search'));
  // if(currentQueryParams.q){
  //   // searchIsOpen = true;
  //   searchInitialValue = currentQueryParams.q;
  // }

  // const [searchIsOpen, setSearchIsOpen] = useState(false);
  // const [searchInitialValue, setSearchInitialValue] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = (val) => setIsMobileMenuOpen(typeof val === 'boolean' ? val : !isMobileMenuOpen);

  return (
    <Header
      isMobileMenuOpen={isMobileMenuOpen}        
      toggleMobileMenu={toggleMobileMenu}
      {...props}
    />
  );
}

export default withRouter(HeaderContainer);