import React from 'react';
import styled from 'styled-components';

const ConfirmEmailContainer = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  margin-top: 30px;
`;

const ConfirmEmail = () => {
  return (
    <ConfirmEmailContainer>
      <Title>Thank you for signing up!</Title>
      <p>
        Thank you! Just one more step before you can use edcat:
        Please click the link in the email we've just sent you to confirm your e-mail address.
      </p>
    </ConfirmEmailContainer>
  )
};

export default ConfirmEmail;
