import React, { useCallback, useMemo } from 'react';
import StoryForm, { statusOptions } from '../components/StoryForm';
import FormPage from '../components/FormPage';
import Edcat from 'services/edcat';
import useApiCall from 'hooks/useApiCall';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import { editStoryChangedAtTimeFormat, editStoryCreatedAtTimeFormat } from '../constants';
import { useStore } from 'store';
import AccessDeniedMessage from '../components/AccessDeniedMessage'
import NotFoundStoryMessage from '../components/NotFoundStoryMessage'
import styled from 'styled-components';

const Underlined = styled.span`
  text-decoration: underline;
`

const EditStory = ({ history, match }) => {
  const slug = get(match, 'params.id')
  const [user] = useStore('user');
  const onSubmit = useCallback(async (formData) => { const response = await Edcat.stories.edit(slug, formData); history.push(`/stories/${response.slug}`); }, [history, slug]);
  const { data, component, error } = useApiCall(() => Edcat.stories.getOne(slug));

  let visibility = 'public';
  if (data) {
    if (!data.is_public) {
      visibility = 'private'
    }
    if (data.is_draft) {
      visibility = 'draft'
    }
  }

  const values = useMemo(() => data ? ({
    ...data.type && { type: { value: lowerCase(data.type), label: data.type } },
    ...data.status && { status: find(statusOptions, { value: lowerCase(data.status) }) },
    // visibility: 'private', // ? TODO:
    ...data.title && { title: data.title },
    ...data.vimeo_id && { vimeo_id: data.vimeo_id },
    ...data.simplecast_episode_id && { simplecast_episode_id: data.simplecast_episode_id },
    ...data.subtitle && { subtitle: data.subtitle },
    ...data.website && { website: data.website },
    ...data.audio_url && { audio_url: data.audio_url },
    ...data.slug && { slug: data.slug },
    ...data.publishing_date && { publishing_date: data.publishing_date },
    ...data.text && { text: data.text },
    ...data.related_event && { related_event: {value: data.related_event.key, label: data.related_event.value} },
    ...data.image && { image: [data.image] },
    ...data.keywords && Array.isArray(data.keywords) && { keywords: map(data.keywords, keyword => ({ value: keyword.key, label: keyword.value })) },
    ...data.entities && Array.isArray(data.entities) && { entities: map(data.entities, entity => ({ value: entity.key, label: entity.value })) },
    ...data.items && Array.isArray(data.items) && { items: map(data.items, ({ key, ...keyword }) => ({ ...keyword, value: keyword.key })) },
    is_highlighted: data.is_highlighted,
    visibility: visibility,
    author: data.author,
  }) : {}, [data])
  const { can_edit } = data || {};
  if (!user) return <AccessDeniedMessage />;
  if (error && error.message && typeof error.message === 'string' && typeof error.message.includes === 'function' && error.message.includes('404')) {
    return <NotFoundStoryMessage />;
  }
  if (component) return component;
  if (typeof data !== 'object') return null;
  if (!can_edit) { return 'access denied' }

  const { author, modified_by, changed, created } = data;
  return (
    <FormPage
      title="edit story"
      subtitle={() => { return <>{`Created ${moment.unix(created).format(editStoryCreatedAtTimeFormat)} by `}<Underlined >{author}</Underlined>{`, last changed ${moment.unix(changed).format(editStoryChangedAtTimeFormat)} by `}<Underlined >{modified_by}</Underlined></> }}
    >
      <StoryForm
        isAdmin={user.is_admin}
        isStoryAdmin={user.is_story_admin}
        userUsername={user.username}
        onSubmit={onSubmit}
        values={values} />
    </FormPage>
  )
}

export default EditStory
