import React, { useState, useMemo, useRef } from 'react';
import { useStore } from 'store';
import StoryList from '../components/StoryList';
import { Link } from 'react-router-dom';
import { Button, FadeIn } from 'components';
import { PageTitle } from 'components/styled';
import styled from 'styled-components';
import find from 'lodash/find';
import { media } from 'theme';
import FiltersContainerTogglable from './components/FiltersContainerTogglable';
import { Sticky } from 'react-sticky';
import useBreakpoint from 'hooks/useBreakpoint';
import classnames from 'classnames';

const MyStoriesLinkMdStyled = styled(Link)`
  margin-right:auto;
  margin-bottom: 15px;
  font-size:17px;
`
const MyStoriesLinkMd = ({ children }) => <MyStoriesLinkMdStyled to="/my-stories">{children}</MyStoriesLinkMdStyled>;

const OptionsRow = styled.div`
  width: 100%;
  display: none;
  font-size: 14px;
  ${media.lessThan("md")`
    display: flex;
  `}
`;
const ShowFiltersSpan = styled.span`
  margin-left:auto;
  margin-bottom: 15px;
  cursor: pointer;
  font-size:17px;
  ${props => props.showFilters && `
    display:none;
  `}
`
const NoStories = styled.span`
  font-size: 34px;
  margin-top: 40px;
  display: block;
`
const StoriesPageTitle = styled(PageTitle)`
  margin-top: 16px;
  margin-bottom: 16px;
  ${props => props.isMyStories && `
  margin-top: 0px;
  text-align: unset;
  `}
  ${media.lessThan("md")`
    text-align: unset;
  `}
`
const StickyElementContainer = styled.div`
  background-color: white;
  z-index:99;
`
const StickyContainer = styled.div`
  ${'' /* margin-bottom: 57px; */}
`
export const CreateStoryLink = (props) => (
  <Link {...props} className={classnames("create-a-story", props.className)} to="/stories/new">
    <Button className="float-right" marginLeftWidth="150px" unrolled style={{ ...props.buttonStyle }}>create a story</Button>
  </Link>
)


const Stories = ({ stories, loading, toggleKeywordFilters, keywordFilters, storyTypeFilters, toggleStoryTypeFilters, Component, count, isMyStories, statusFilter, setStatusFilter }, ref) => {
  const [user] = useStore('user');
  const [showFilters, setShowFilters] = useState(false);
  const allFilters = useMemo(() => [...keywordFilters, ...storyTypeFilters], [keywordFilters, storyTypeFilters])
  const isSelectedFilters = find(allFilters, { selected: true });
  const pageTitle = isMyStories ? 'my stories' : 'edcat stories';
  const compensatedHeight = useRef(0);
  const stickyRef = useRef(null)
  const { minWidth } = useBreakpoint();

  const FiltersContainerTogglableWithSticky = ({ style, isSticky }) => (
    <StickyElementContainer style={{ ...style, ...isSticky && { top: 96 } }} className={isSticky ? 'sticky' : undefined}>
      <FiltersContainerTogglable
        isSticky={isSticky}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        isMyStories={isMyStories}
        allFilters={allFilters}
        toggleKeywordFilters={toggleKeywordFilters}
        keywordFilters={keywordFilters}
        toggleStoryTypeFilters={toggleStoryTypeFilters}
        count={count}
        storyTypeFilters={storyTypeFilters}
        pageTitle={pageTitle}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        user={user}
      ></FiltersContainerTogglable>
    </StickyElementContainer>);

  return (
    <div className="Stories" ref={ref}>
      <div className="stories-page-title-container">
        {user && <CreateStoryLink className={isMyStories ? "my-stories" : undefined} />}
        <StoriesPageTitle isMyStories={isMyStories}>{pageTitle}</StoriesPageTitle>
      </div>
      {count || isSelectedFilters || loading || (statusFilter !== 'all')
        ? <>
          <StickyContainer className="sticky-container">
            {minWidth >= 768 ? <Sticky ref={stickyRef}
              topOffset={-25}
              disableCompensation // fixes flicker with resizing margin
            >
              {({ style, isSticky, calculatedHeight }) => {
                compensatedHeight.current = isSticky ? Math.max(compensatedHeight.current || 0, calculatedHeight || 0) : 0;
                if (stickyRef.current) { stickyRef.current.placeholder.style['padding-top'] = `${compensatedHeight.current}px`; };
                return FiltersContainerTogglableWithSticky({ style, isSticky });
              }}
            </Sticky> : FiltersContainerTogglableWithSticky({})}
          </StickyContainer>
          <OptionsRow>
            {!isMyStories && <MyStoriesLinkMd>view my stories</MyStoriesLinkMd>}
            {!showFilters && <ShowFiltersSpan onClick={() => setShowFilters(true)}>show filter</ShowFiltersSpan>}
          </OptionsRow>
          {stories && stories.length ? (
            <FadeIn duration={700}>
              <StoryList listType={isMyStories ? "myStories" : "stories"} stories={stories || []} />
            </FadeIn>
          ) : null}
        </>
        : <NoStories>{isMyStories ? 'You have not created ' : 'There are not '}any stories yet.</NoStories>
      }
      {Component || null}
    </div>
  );
}

const ForwardedRefStories = React.forwardRef(Stories)
export default ForwardedRefStories

