import React from 'react';
import styled from 'styled-components';

export const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 25px;
`;

export const FormLimiter = styled.div`
  max-width: 890px;
  margin: 0 auto;
`;

export const Page = styled.div`
  background-color: #EBECEC;
  position: absolute;
  left: 0;
  width: 100vw;
  padding: 20px;
`;

export const FormHeader = styled.div`
  color: #131413;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 17px;
`;

export const FormTitle = styled.h1`
  font-size: 36px;
  letter-spacing: 0.1px;
  line-height: 43px;
  margin: 0;
  font-weight: normal;
`;

export const FormSubtitle = styled.div`
  text-align: right;
  font-size: 14px;
`;



const FormPage = ({ children, title: Title, subtitle: Subtitle }) => {
  return (
    <Page>
      <FormLimiter>
        <FormHeader>
          <FormTitle>
            {(typeof Title === 'function') ? <Title /> : Title}
          </FormTitle>
          <FormSubtitle>
            {(typeof Subtitle === 'function') ? <Subtitle /> : Subtitle}
          </FormSubtitle>
        </FormHeader>
        <FormContainer>
          {children}
        </FormContainer>
      </FormLimiter>
    </Page>
  )
}

export default FormPage
