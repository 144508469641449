import React from 'react';
import { Link } from 'components';
import settings from 'settings';
import styled from 'styled-components';
import { media } from 'theme';

const Container = styled.footer`
  padding: 20px var(--horizontal-padding) 20px;    
  ${media.greaterThan('md')`      
    padding: 20px var(--horizontal-padding) 20px;
  `}
  min-height: 232px;
  display: flex;  
  align-items: center;
`;

const Grid = styled.div`
  flex: 1 1 auto;
  ${media.greaterThan('md')`
    display: flex;
    justify-content: space-between;      
  `}
`;

const List = styled.ul`
  margin: 0;    
  padding: 0;    
  list-style-type: none; 
  ${media.lessThan('sm')`
    display: flex;
    justify-content: center;
    &:not(:last-child){
      margin: 0 0 24px;        
    }
  `}       
  ${media.greaterThan('md')` 
    flex: 0 1 auto; 
    display: inline-flex;    
  `}    
`;

const Item = styled.li`
  &:not(:last-child){
    margin-right: 15px;
    ${media.greaterThan('sm')`
      margin-right: 25px;
    `}      
  }
  & > * {
    color: #282828;
    font-size: 12px;
    letter-spacing: 0.01px;
    line-height: 12px;
  }
`;

const Footer = () => (
  <Container>
    <Grid>
      <List>
        <Item><Link className="footer-link" to="/about/mission/">About edcat </Link></Item>
        <Item><a className="footer-link" href={settings.feedbackUrl}>Send Feedback</a></Item>
        <Item><Link className="footer-link" to="/about/help/">Get Help</Link></Item>
      </List>
      <List>
        <Item><span className="footer-text">© edcat {new Date().getFullYear()}</span></Item>
        <Item><Link className="footer-link" to="/info/privacy-policy/">Privacy Policy</Link></Item>
        <Item><Link className="footer-link" to="/info/cookie-policy/">Cookie Policy</Link></Item>
        <Item><Link className="footer-link" to="/info/terms-and-conditions/">Terms and Conditions</Link></Item>
      </List>
    </Grid>
  </Container>
);

export default Footer;
