import styled, { css } from 'styled-components';
import { media } from 'theme';
import { Touchable } from '../styled';

export const Button = styled(Touchable)`
  display: inline-block;
  cursor: pointer;  
  ${props => props.theme.mixins.outline(0)}
  
  min-height: ${props => props.theme.buttonHeight};
  padding: ${props => props.theme.buttonPaddingVertical} ${props => props.theme.buttonPaddingHorizontal};
  
  border: 1px solid ${props => props.theme.buttonBorderColor};
  border-radius: ${props => props.theme.buttonBorderRadius};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${props => props.theme.buttonFontSize};
  text-align: center;
  color: ${props => props.theme.buttonTextColor};
  background-color: ${props => props.theme.buttonBackgroundColor};
  cursor: pointer;

  width: 100%;
  ${media.greaterThan("md")`
    width: auto;
  `}

  ${props => props.marginLeftWidth && css`
    ${media.greaterThan("md")`
    width: ${props.marginLeftWidth};
    margin-left: calc(${props.marginLeftWidth} * -1);
    `}
  `}
  ${props => props.marginRightWidth && css`
    ${media.greaterThan("md")`
    width: ${props.marginRightWidth};
    margin-left: calc(${props.marginRightWidth} * -1);
    `}
  `}
  
  ${props => !props.noHoverEffects && css`
    &:focus,&:hover,&:active{
      color: ${props => props.theme.buttonBackgroundColor};
      background-color: ${props => props.theme.buttonTextColor};
      text-decoration: none;
      svg {
        filter:invert(1);
      }
    }
  `}

  &:disabled{
    &:focus,&:hover,&:active{
      color: ${props => props.theme.buttonTextColor};
      background-color: ${props => props.theme.buttonBackgroundColor};      
      cursor: default;      
    }
  }  
  

  ${props => props.inverted && `
    color: ${props.theme.buttonBackgroundColor};
    background-color: ${props.theme.buttonTextColor};
    ${!props.noHoverEffects && `
      &:focus,&:hover,&:active{
        color: ${props.theme.buttonTextColor};
        background-color: ${props.theme.buttonBackgroundColor};
      }
    `}
      &:disabled{
        &:focus,&:hover,&:active{
          color: ${props.theme.buttonBackgroundColor};
          background-color:  ${props.theme.buttonTextColor};
        }
      }  
  `}

  ${props => props.round && `
    border-radius: 20px;
  `}

  ${props => props.unrolled && `
    border-radius: 0px;
  `}

  ${props => props.fixed && `
    width: 160px;
  `}

  ${props => props.small && `
    font-size: calc(${props.theme.buttonFontSize} * 0.8);
  `}

  ${props => props.link && `
    border: none;
    font-size: 14px;
    &:hover{
      text-decoration: underline;
    }
  `}
  ${props => props.gray && `
    color: hsl(0,0%,40%);
  `}

  ${props => props.wide && `
    padding-left: calc(${props.theme.buttonPaddingHorizontal} * 1.2);
    padding-right: calc(${props.theme.buttonPaddingHorizontal} * 1.2);
  `}
  ${props => props.wider && `
    padding-left: calc(${props.theme.buttonPaddingHorizontal} * 2);
    padding-right: calc(${props.theme.buttonPaddingHorizontal} * 2);
  `}
  ${props => props.superWide && `
    padding-left: calc(${props.theme.buttonPaddingHorizontal} * 4);
    padding-right: calc(${props.theme.buttonPaddingHorizontal} * 4);
  `}

  ${props => props.bold && `
    font-weight: normal;
  `}

  ${props => props.fluid && `
    & {
      width: 100%;
    }
  `}
`;

export default Button;
