import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import User from "./User";
import Edcat from "services/edcat";
import useApiCall from "hooks/useApiCall";
import { uniqBy, isNil } from "lodash";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { HeaderContext } from "constants/context";
import { usePrevious } from "utils/helpers";
import { sortOptions } from "./components/SortSelect";

const itemsFilters = [
  {
    type: "topics",
    values: [],
  },
  {
    type: "category",
    values: [],
  },
  {
    type: "artists",
    values: [],
  },
  {
    type: "publishers",
    values: [],
  },
];

const UserContainer = ({
  match: {
    params: { username },
  },
}) => {
  const contextValue = useContext(HeaderContext) || {};
  useEffect(() => {
    const { setAlwaysShow } = contextValue;
    setAlwaysShow(true);
    return () => {
      setAlwaysShow(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(sortOptions[0]);
  const [loadedItems, setLoadedItems] = useState([]);
  const [count, setCount] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    type: null,
    value: null,
  });
  //hook used to partially load stories (skip/limit)
  const [loadingReally, setLoading] = useState(true); //removes flicker when select and deselect one keyword
  const { data: userData, loading: userLoading } = useApiCall(() =>
    Edcat.user({ username })
  );

  const { data, component, loading } = useApiCall(
    () =>
      Edcat.userItems({
        username,
        filter: selectedFilter,
        sort: sort.value,
        page,
      }),
    [page, selectedFilter, sort]
  );
  //add partially loaded pages to all loaded pages after data will be loaded
  const prev = usePrevious({ loading });

  useEffect(() => {
    if (!loading && isInitialLoading && !userLoading)
      setIsInitialLoading(false);
    if (loading || !data || !userData || userLoading) return;
    !isNil(data.count) && setCount(data.count);
    data.results && data.results.length && page === 1
      ? setLoadedItems(data.results)
      : setLoadedItems((loadedItems) =>
          uniqBy([...loadedItems, ...data.results], "slug")
        );
    if (userData) {
      itemsFilters[0].values = userData.topics_filter;
      itemsFilters[1].values = userData.categories_filter;
      itemsFilters[2].values = userData.artists_filter;
      itemsFilters[3].values = userData.publishers_filter;
    }
  }, [data, isInitialLoading, loading, page, userData, userLoading]);
  if (prev.loading && !loading && data && loadingReally)
    setLoading(false); //removes flicker when select and deselect one keyword

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: !!(data || {}).next,
    onLoadMore: () => setPage(page + 1),
    scrollContainer: "window",
    threshold: 500, //px
  });
  if (isInitialLoading && userData) setIsInitialLoading(false);
  else if (isInitialLoading) return null;

  return (
    <User
      loading={loadingReally}
      setLoading={(val)=> setLoading(val)}
      userData={userData}
      ref={infiniteRef}
      Component={component}
      count={count}
      sort={sort}
      setSort={setSort}
      items={loadedItems}
      username={username}
      filters={itemsFilters}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      selectedFilter={selectedFilter}
      setSelectedFilter={(val)=> {
        setPage(1);
        setSelectedFilter(val);
      }}
    />
  );
};

export default UserContainer;
