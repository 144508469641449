import React from 'react';

function PlayIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 12 15">
			<path fill="currentColor" d="M0 0v14.33l11.26-7.165L0 0z" />
		</svg>
	);
}

export default PlayIcon;
