/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ItemList from "../components/ItemList";
import { SortSelect } from "./components/SortSelect";
import { Filter } from "./components/Filter";
import { Button, FadeIn } from "components";
import { useUserItemsFilters } from "hooks/useUserItemsFilters";
import classNames from "classnames";
import useReactRouter from "use-react-router";

export const isArrayNotEmpty = (arr) => !!(arr && arr.length);
const getFilter = ({ filterName, options, onClickFilter, isBig = false }) =>
  isArrayNotEmpty(options) && (
    <div className={isBig ? "item-filter big" : "item-filter"}>
      <Filter
        filterName={filterName}
        options={options}
        onClick={onClickFilter}
      />
    </div>
  );

const useFilterChildren = ({
  openedFilterCategory,
  onClickFilter,
  filtersObject,
}) => {
  return [
    getFilter({
      options: filtersObject,
      filterName: openedFilterCategory,
      onClickFilter,
      isBig: true,
    }),
  ];
};

const User = (
  {
    userData,
    items,
    loading,
    Component,
    sort,
    setSort,
    filters,
    selectedFilters,
    setSelectedFilters,
    username,
    selectedFilter,
    setSelectedFilter,
    setLoading,
  },
  ref
) => {
  const { history } = useReactRouter();
  const {
    filterCategoryItems,
    onClickFilter,
    openedFilterCategory,
    filtersObject,
    setOpenedFilterCategory,
  } = useUserItemsFilters({
    filters,
    selectedFilters,
    setSelectedFilters,
  });
  const filterData = (option, type) => {
    onClickFilter(option, type);
    if (["topics", "publishers", "artists", "category"].includes(option.id)) {
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setLoading(true);
    setSelectedFilter({
      type,
      value: option.slug,
    });
  };

  const displayAll = () => {
    setLoading(true);
    if (openedFilterCategory) setOpenedFilterCategory(false);
    setSelectedFilter({
      type: "all",
      value: "all",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const displayFeatureItems = () => {
    if (!selectedFilter.value) return;
    if (openedFilterCategory) setOpenedFilterCategory(false);
    setSelectedFilters([]);
    setLoading(true);
    setSelectedFilter({
      type: null,
      value: null,
    });
  };

  const filterChildren = useFilterChildren({
    openedFilterCategory,
    onClickFilter: filterData,
    filtersObject,
  });

  const goToShop = () => {
    history.push({
      pathname: `/users/${username}/details/`,
      state: {
        userData,
      },
    });
  };

  return (
    <div className="User" ref={ref}>
      <div className="user-header-container">
        <div className="user-page-title-container">
          <img
            className="pointer"
            src={userData.image}
            onClick={() => displayFeatureItems()}
          />
          <div>
            <div className="shopTitle">{userData.name}</div>
            <div className="shopLocation">
              {userData.location}
              <Button
                className="hidden-md more-info"
                onClick={() => goToShop()}
              >
                More Info
              </Button>
            </div>
          </div>
        </div>
        <Button className="hidden-sm" onClick={() => goToShop()}>
          More Info
        </Button>
      </div>
      <div>
        <div className="item-filter-container">
          <div className="item-filter-wrapper">
            <div className="item-filter">
              <Filter
                className="selected-filter"
                options={
                  window.innerWidth < 800 && filterCategoryItems.length > 4
                    ? filterCategoryItems.filter(
                        (item) => item.id === undefined
                      )
                    : filterCategoryItems
                }
                onClick={filterData}
              />
            </div>

            <div className="select-container">
              {!selectedFilter.value ? (
                <Button
                  onClick={() => displayAll()}
                  className="loadmore"
                  style={{ margin: "auto" }}
                >
                  Show all {userData.items_numbers.items_for_sale} publications
                </Button>
              ) : (
                <>
                  <span>Sort by</span>
                  <SortSelect value={sort} onChange={setSort} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {openedFilterCategory === "category" && (
        <div className="item-filter-container">
          <div className="header-filter">
            {userData.categories_header.map((item, i) => (
              <Button key={i} onClick={() => filterData(item, item.type)}>
                <div className="filter-button-content">
                  <div className="filter-name">{item.name}</div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      )}
      {openedFilterCategory && (
        <div
          className={classNames("item-filter-container", "filter-children", {
            "filter-children-opened": openedFilterCategory,
          })}
        >
          {filterChildren}
        </div>
      )}

      {!loading && items && items.length ? (
        <FadeIn duration={700}>
          <ItemList
            featuredItem={selectedFilter.value ? false : true}
            listType={"relatedStories"}
            items={items || []}
          />
        </FadeIn>
      ) : null}
      {Component && !loading && (
        <Button className="loadmore">Load More ...</Button>
      )}
      {!loading &&
      items &&
      items.length &&
      !selectedFilter.value &&
      userData.items_numbers ? (
        <Button
          onClick={() => displayAll()}
          className="loadmore"
          style={{ margin: "auto" }}
        >
          Show all {userData.items_numbers.items_for_sale} publications
        </Button>
      ) : null}
    </div>
  );
};

const ForwardedRefItems = React.forwardRef(User);
export default ForwardedRefItems;
