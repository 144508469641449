import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'components';

const NotFoundStoryMessage = () => {
  return (
    <div>
      <p>The story is not found. Go back and try again</p>
      <Link to="/stories">
        <Button className="float-left">Stories List</Button>
      </Link>
    </div>
  )
}
export default NotFoundStoryMessage;