import React, { Component } from 'react';
import Events from './Events';

export default class EventsContainer extends Component {
  render() {
    return (
      <Events {...this.props} />
    )
  }
}
