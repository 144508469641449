import _ from 'lodash';

export const storyTypes = [
  {
    value: 'portrait',
    label: 'Portrait',
  },
  {
    value: 'selection',
    label: 'Selection',
  },
  {
    value: 'review',
    label: 'Review',
  },
  {
    value: 'report',
    label: 'Report',
  },
  {
    value: 'interview',
    label: 'Interview',
  }
];

export const relatedBooks = [
  {
    image: "https://stage.edcat.net/media/items/Maren.jpg.600x850_q85.jpg",
    title: "Sunkabuck",
    subtitle: "Erik Steinbrecher",
    price: "30 CHF",
    url: "https://stage.edcat.net/item/girl/",
  },
  {
    image: "https://stage.edcat.net/media/items/Olematud_Raamatud_ENG_copy.jpg.600x850_q85.jpg",
    title: "Sunkabuck 2",
    subtitle: "Erik Steinbrecher",
    price: null,
    url: "https://stage.edcat.net/item/girl/",
  },
  {
    image: "https://stage.edcat.net/media/items/b36393674e.jpg.600x850_q85.jpg",
    title: "Sunkabuck 3",
    subtitle: "Erik Steinbrecher",
    price: "20 CHF",
    url: "https://stage.edcat.net/item/girl/",
  },
];

export let stories = [
  {
    type: 'Portrait',
    title: 'Feel the nordic vibe: the artists’ books of Lodret Vandret',
    author: 'Angelica Lala',
    image: "https://www.w3schools.com/w3css/img_lights.jpg",
    slug: "slug-1",
  },
  {
    type: 'Review',
    title: 'Big in Japan: More than 50.000 visitors at Tokyo Art Book Fair',
    author: 'Angelica Lala',
    image: "https://www.w3schools.com/w3css/img_forest.jpg",
    slug: "slug-2",
  },
  {
    type: 'Selection',
    title: '50 amazing found photography books',
    author: 'Angelica Lala',
    image: "https://www.w3schools.com/w3css/img_mountains.jpg",
    slug: "slug-3",
  },
  {
    type: 'Review',
    title: 'Supersize it: Prints larger than most walls',
    author: 'Angelica Lala',
    image: "https://www.w3schools.com/w3css/img_nature.jpg",
    slug: "slug-4",
  },
  {
    type: 'Review',
    title: 'PrintRoom’s picks from the New York Art Book Fair 2018',
    author: 'Angelica Lala',
    image: "https://www.w3schools.com/w3css/img_snowtops.jpg",
    slug: "slug-5",
  },
];
stories = _.shuffle([...stories, ...stories]).map((o, i) => ({ ...o, slug: o.slug + i }));

export const keywords = ['NY Art Book Fair', 'USA', 'Asia', "Artists' books", 'Art editions', 'Zines', 'Prints'];

export const storyPreview = {
  type: 'Review',
  title: 'PrintRoom’s picks from the New York\nArt Book Fair 2018',
  author: 'PrintRoom Rotterdam',
  image: "https://www.w3schools.com/w3css/img_lights.jpg",
  createdAt: "Oct 13 2018",
  modifiedAt: "Oct 19 2018",
  tags: ["NY Art Book Fair", "USA", "PrintRoom", "Artists' books", "Art editions", "Zines", "Prints"],
  text: "Celebration of this year's harvest from the fair, with Ruth van Beek, Eva Wilson, Stefan Marx, Adam Gibbons, Werkplaats Typografie and thanks to the readers: Kate Briggs, Constant Dullaart, Cramer Florian, Judith Vrancken and Mirthe Berentsen. Thank you all for joining!! and thank you PrintRoom team for all the good work! Asnate Bočkis , Nóra Van Vredenrijk, Eun J Lee, Carson Parris, Hilal Mutluel, Cen Giz, Nicole Martens",
  featuredCatalog: _.shuffle([...relatedBooks]),
  catalog: _.shuffle([...relatedBooks, ...relatedBooks, ...relatedBooks, ...relatedBooks]),
  related: _.shuffle(stories),
}

export const editStoryCreatedAtTimeFormat='YYYY-MM-DD HH:mm'
export const editStoryChangedAtTimeFormat='YYYY-MM-DD'
export const storyPreviewTimeFormat='MMM DD YYYY'