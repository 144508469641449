import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { Events, Home, Items, Marketplace, Publishers, NotFound, Artists, StoriesList, StoryPreview, CreateStory, EditStory, EmailConfirmationSuccess, PreviewUser, PreviewShop } from './pages';
import ThemeProvider from './theme';
import './lib/toast';

const App = () => (
  <ThemeProvider>
    <Router>
      <MainLayout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/activation-success" exact component={EmailConfirmationSuccess} />
          <Route path="/events" exact component={Events} />
          <Route path="/artists" exact component={Artists} />
          <Route path="/items" exact component={Items} />
          <Route path="/marketplace" exact component={Marketplace} />
          <Route path="/my-stories" exact render={(props) => <StoriesList key='1' {...props}></StoriesList>} />
          <Route path="/stories" exact render={(props) => <StoriesList key='2' {...props}></StoriesList>} />
          <Route path="/users/:username" exact component={PreviewUser} />
          <Route path="/users/:username/details" exact component={PreviewShop} />
          <Route path="/stories/new" exact component={CreateStory} />
          <Route path="/stories/edit/:id" exact component={EditStory} />
          <Route path="/stories/:id" exact component={StoryPreview} />
          <Route path="/publishers" exact component={Publishers} />
          <Route component={NotFound} />
        </Switch>
      </MainLayout>
    </Router>
  </ThemeProvider>
)

export default App;
