import React from 'react';
import styled from 'styled-components';
import { media } from 'theme';
export const PageTitle = styled.h1`
  text-align: center;
  font-size: 3em;
  font-family: baskerville;
  * {
    font-family: baskerville;
  };
  font-weight: normal;
  margin-top: 14px;
  ${media.lessThan("md")`
  font-size: 3em;
  `};
  ${'' /* ${media.lessThan("sm")`
  font-size: 2em;
  text-align: left;
  `}; */}
`;

export const SectionTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.78px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 40px;
  &:after {
    content: ' ';
    border-bottom: 1px solid #000;
    width: 100%;
    margin-top: 0.5em;
    display: block;
  }
`;

export const Touchable = styled.button`
  display: block;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
`;

export const A = styled.a`
  ${props => props.theme.mixins.outline(0)}
  display: inline-block;
  &:hover, &:active, &:focus {
    color: ${props => props.theme.hoverLinkColor};
    text-decoration: underline;
  }
  ${props => props.noUnderline && `
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  `}
`;

export const CloseIconSVG = (color) => (props) => (
  <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill={color} stroke={color} strokeWidth=".4">
        <g transform="translate(11 12)">
          <rect transform="translate(12.996 12.5) rotate(-45) translate(-12.996 -12.5)" x="-3.2541" y="11.29" width="32.5" height="2.4194" />
          <rect transform="translate(12.996 12.5) rotate(45) translate(-12.996 -12.5)" x="-3.2541" y="11.29" width="32.5" height="2.4194" />
        </g>
      </g>
    </g>
  </svg>
);


export const CloseIcon = styled(CloseIconSVG('black'))`
  height: 40px;
  max-width: 40px;
  min-width: 40px;
`

export const CloseButton = styled(Touchable)`
  display: inline-block;
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
`