import React, { useCallback } from 'react';
import { Button } from 'components';
import { CloseButton, CloseIcon, CloseIconSVG } from 'components/styled';
import styled from 'styled-components';
import { media } from 'theme';
import { orderBy } from 'lodash'
import { Link } from 'react-router-dom';
const Container = styled.div`
  position: relative;
  font-size: 16px;
  ${media.greaterThan('xl')`
    display: flex; 
    align-items: flex-start;
    padding: 0 40px;
  `}
  display: flex;
  flex-direction: row;
  ${props => props.isExpanded && `
    padding-right: 30px;
  `}
`;

const FilterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 auto 0 auto;
  padding: 0;
  >*:not(:last-child){
    padding-right: 9px;
  } 
`;
const FilterItem = styled.li`
  flex: 0 0 auto;
  margin-bottom: 12px;
  &   
  &.newline {
    flex-basis: 100%;
  }
`;

const FilterTitle = styled.span`
  display: none;
  margin-bottom: 8px;
  ${media.lessThan('md')`
    font-size: 17px
    display: block;
  `}
`;

export const ItemCloseIcon = styled(CloseIconSVG('white'))`
  height: 23px;
  margin-left: 7px;
  margin-right: -5px;
  margin-top: -8px;
  margin-bottom: -8px;
  filter:invert(0);
`

const FilterButton = ({ disabled, label, selected, inverted, noHoverEffects, showCloseIcon, hideCloseIconWhenDeSelected, ...rest }) => (
  <Button
    round
    disabled={disabled}
    inverted={inverted}
    small
    wide
    noHoverEffects={noHoverEffects}
    {...rest}
  >
    {label}
    {(showCloseIcon && (hideCloseIconWhenDeSelected ? inverted : true)) ? <ItemCloseIcon /> : null}
  </Button>
);

const useFilterOptions = ({ options, isHidden, hideSelected, showOnlySelected, showFirst, noHoverEffects, showSortSelected }) => {
  return React.useMemo(() => {
    let displayableOptions = options.filter(({ selected }) => {
      if (showOnlySelected) return selected;
      else if (hideSelected) return !selected;
      else return true;
    });
    if (showSortSelected) displayableOptions = orderBy(displayableOptions, ['selected'], ['desc'])
    let hiddenOptions = [];
    if (showFirst && isHidden) {
      let spliceValue = showFirst;
      if (showSortSelected) {
        const selectedCount = displayableOptions.reduce((prev, curr) => prev + curr.selected, 0)
        spliceValue = Math.max(showFirst, selectedCount);
      }
      hiddenOptions = displayableOptions.splice(spliceValue);
    }
    return [displayableOptions, hiddenOptions];
  }, [options, showSortSelected, showFirst, isHidden, showOnlySelected, hideSelected]);
}
const borderNoneStyle = { border: 'none' };

const Filter = ({
  closeAfterSelected, // hide options after clocked on 
  disabled, // is filter disabled
  filters, //array of filters - buttons
  hideCloseIconWhenDeSelected, //hide close icon on de-selected options
  hideSelected, //hide selected options
  inverted, //invert selected and deselected options
  label,
  noHoverEffects, //no mouse hover effects
  onToggle, //on filter button clicked
  showAllLabel = 'Show All',//'show all' button label
  showAllProps = {},
  showCloseIcon, //show close icon on options
  showFirst, //int, show at least n options
  showOnlySelected, //show only selected options
  showSortSelected, //show selected options at first
  link, //redirect to page onClick
}) => {
  const [isHidden, setIsHidden] = React.useState(true);
  const [displayableOptions, hiddenOptions] = useFilterOptions({ options: filters, isHidden, hideSelected, showOnlySelected, showFirst, showSortSelected });
  const [hide, show] = [useCallback(() => setIsHidden(true), []), useCallback(() => setIsHidden(false), [])]
  const filterButtonOnClick = useCallback((e, option) => { if (disabled) return; e && e.target && e.target.blur(); onToggle(option); if (closeAfterSelected && !option.selected) { hide() } }, [closeAfterSelected, disabled, hide, onToggle]);
  React.useEffect(() => {
    if (displayableOptions.length <= showFirst && !isHidden) { hide() } // if displayable options less than showFirst and not hidden
  }, [displayableOptions.length, showFirst, isHidden, setIsHidden, hide]);
  return (
    <div className="filter-container">
      <FilterTitle className="filter-title">{label}</FilterTitle>
      <Container isExpanded={!isHidden}>
        <FilterList className="filter-list">
          {displayableOptions.map((option) => {
            const Wrapper = option.Wrapper || React.Fragment;
            const WrapperLink = link ? Link : React.Fragment;
            const linkURL = link ? ((typeof link === 'function') ? link(option.value) : `${link}${option.value}`) : undefined
            return (
              <WrapperLink key={option.value} {...linkURL && { to: linkURL }}>
                <Wrapper>
                  <FilterItem>
                    <FilterButton
                      inverted={inverted ? !option.selected : option.selected}
                      disabled={link ? false : disabled}
                      onClick={(e) => filterButtonOnClick(e, option)}
                      noHoverEffects={noHoverEffects}
                      showCloseIcon={showCloseIcon}
                      hideCloseIconWhenDeSelected={hideCloseIconWhenDeSelected}
                      {...option}
                    />
                  </FilterItem>
                </Wrapper>
              </WrapperLink>
            )
          })}
          {hiddenOptions.length && isHidden ? ( //show all button
            <FilterItem>
              <FilterButton
                label={showAllLabel}
                onClick={show}
                style={borderNoneStyle}
                noHoverEffects={noHoverEffects}
                showCloseIcon={showCloseIcon}
                {...showAllProps}
              />
            </FilterItem>
          ) : null}
        </FilterList>
        {!isHidden && <CloseButton onClick={hide}><CloseIcon /></CloseButton>}
      </Container>
    </div >
  )
}

Filter.defaultProps = {
  filters: [],
}

export default Filter;
