import React from 'react';
import { ThemeProvider } from 'styled-components';
import { generateMedia } from "styled-media-query";

export const media = generateMedia({
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
});

const black = '#000000';
const white = '#FFFFFF'; 
// const gray = '#3D3D3D';
const blue = '#0025FF';

const outline = (outline) => (props) => `
  outline: ${outline};
	outline-offset: ${outline};
	:active, :hover, :focus {
	    outline: ${outline};
	    outline-offset: ${outline};
	}		
`;

const textColor = black;
export const theme = {
  // bodyBackgroundColor: white,
  // placeholderColor: '#cecece',
  // headingColor: black,
  hoverLinkColor: 'rgba(0,0,0,0.8)',
  activeLinkColor: blue,
  textColor,
  media,
  mixins: {
    outline,
  },
  fontFamily: 'nimbus-sans, sans-serif',
  buttonBorderColor: black,
  buttonTextColor: textColor,
  buttonBackgroundColor: white,
  buttonBorderRadius: '3px',
  buttonHeight: '40px',
  buttonFontSize: '18px',
  buttonPaddingVertical: '11px',
  buttonPaddingHorizontal: '16px',
  eventFooterBackgroundColor: '#590F96',
  modalZIndex: 500,
};

const Provider = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

export default Provider;
