import React, { useEffect } from 'react';
import StoryPreview from './StoryPreview';
import Edcat from 'services/edcat';
import useApiCall from 'hooks/useApiCall';
import { normalizeOptions } from '../utils';
import NotFoundStoryMessage from '../components/NotFoundStoryMessage'

const StoryPreviewContainer = ({ match: { params: { id: slug } } }) => {
  // useEffect(() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' }); }, [slug])
  useEffect(() => { window.scrollTo(0, 0) }, [slug])

  //get data for story
  const { data, component, error } = useApiCall(() => Edcat.stories.getOne(slug), [slug]);

  const story = React.useMemo(() => {
    if (!data) return null;
    return {
      ...data,
      relatedStories: data.related_stories,
      featuredItems: Array.isArray(data.items) ? data.items.slice(0, 6) : [],
      items: Array.isArray(data.items) ? data.items.slice(6) : [],
      keywords: normalizeOptions(data.keywords),
    }
  }, [data]);
  if (error && error.message && typeof error.message === 'string' && typeof error.message.includes === 'function' && error.message.includes('404')) {
    return <NotFoundStoryMessage />;
  }
  if (component) {
    return component;
  }

  return (
    <StoryPreview story={story} />
  )
}

export default StoryPreviewContainer
