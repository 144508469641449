import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Button, FadeIn } from 'components';
import PropTypes from 'prop-types';
import settings from 'settings';
import styled from 'styled-components';
import { media } from 'theme';
import useBreakpoint from 'hooks/useBreakpoint';

const Container = styled.div`
  margin: 0 calc( -1 * var(--horizontal-padding));
  ${media.greaterThan('md')`
    margin: 0 auto;
  `}
`;

const Link = styled.a`
  display: block;
  text-decoration: none !important;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Footer = styled.div`
  position: relative;
  z-index: 1;
  min-height: 15%;      
  background-color: ${props => props.color || props.theme.eventFooterBackgroundColor};
  display: flex;
  padding: 15px 25px;
  flex-direction: column;
  align-items: flex-start;
  ${media.greaterThan('lg')` 
    flex-direction: row;
    align-items: center;
  `}
`

const ImageContainer = styled.div`
  height: 345px;
  
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  display: block;  
`;

const Title = styled.h1`
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: 0.23px;
  font-weight: normal;
  margin-bottom: 4px;
  line-height: 20px;
  ${media.greaterThan('lg')` 
    margin: 10px 20px;
    font-size: 24px;
    margin-left: 0;
  `}
`;

const Place = styled.h2`
  color: #FFFFFF;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.23px;
  font-size: 16px;
  flex: 1;
  line-height: 20px;
  span{
    display: block;
    margin-bottom: 4px;
    line-height: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${media.greaterThan('lg')` 
    margin: 10px 20px;
    font-size: 24px;
    span{
      margin-bottom: 0;
      display: inline-block;
      &:last-child {
        float: right;
      }
    }
  `}
`;


const Event = ({ image, title, subline, date, url, details_image, featured_color }) => {
  const { breakpoint } = useBreakpoint();
  const img = ['md', 'lg', 'xl'].includes(breakpoint) ? image : details_image;
  return (        
    <Container>
      <Link href={url}>
        <ImageContainer>
          <Image src={`${settings.ABSOLUTE_URL}${img}`} alt="Event" />
        </ImageContainer>
        <Footer 
          color={featured_color}
        >
          <Title>{title}</Title>
          <Place>
            <span>{subline}</span>
            <span>{date}</span>
          </Place>
        </Footer>
      </Link>
    </Container>
  );
}

Event.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subline: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
}

export default Event;