import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import Button from 'components/Button';
import { media } from 'theme';

const closeIcon = (
  <svg width="18px" height="18px" viewBox="0 0 48 48">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="#000000" strokeWidth="0.4" fill="#000000">
        <g transform="translate(11.000000, 12.000000)">
          <rect transform="translate(12.995856, 12.500000) rotate(-45.000000) translate(-12.995856, -12.500000) " x="-3.25414408" y="11.2903226" width="32.5" height="2.41935484"></rect>
          <rect transform="translate(12.995856, 12.500000) rotate(-315.000000) translate(-12.995856, -12.500000) " x="-3.25414408" y="11.2903226" width="32.5" height="2.41935484"></rect>
        </g>
      </g>
    </g>
  </svg>
);

const GlobalStyle = createGlobalStyle`
  body.ReactModal__Body--open{
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .ReactModalPortal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.theme.modalZIndex};
  }
  .modal-overlay{
    ${props => props.theme.mixins.outline(0)}
    transition: opacity 500ms ease-in-out;
    opacity: 0;
    position: fixed; 
    top: 0;
    left: 0;  
    width: 100%;
    height: 100vh; 
    ${media.greaterThan('sm')`
      padding: 50px 0; 
    `}
    padding: 0; 
    overflow-y: auto; 
    background: rgba(0,0,0,0.3);
  }
  .modal-overlay_after-open{		
    opacity: 1;
  }
  .modal-overlay_before-close{
    opacity: 0;
  }
  
  .modal-content{   
    ${props => props.theme.mixins.outline(0)}
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;  
    pointer-events: none;
    &.fluid {
      width: 100%;
    }
  }

  a {
    text-decoration: underline;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01px;
    margin-bottom: 11px;
  }
`;

// Should use this container, when modal are long. Otherwise we will get cropped content for long modals
const Content = styled.div`
  pointer-events: auto;
  background-color: #fff;  
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  position: relative;
  max-width: 525px;
  width: 100%;
  border-radius: 4px;
  padding: 30px 20px;
  ${media.greaterThan('sm')`
    padding: 30px 40px;
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  display: inlince-block;
  top: 40px;
  right: 15px;
  ${media.greaterThan('sm')`
    right: 35px;
  `}
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.theme.mixins.outline(0)}
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Header = styled.div`
  // padding: 28px 83px 28px 30px;
  // border-bottom: 1px solid #A8A9A9;
  // padding-right: 20px;
`;

const Title = styled.h2`
  margin: 0;
  color: #282828;
  font-size: 34px;
  ${media.greaterThan('sm')`
    font-size: 36px;
  `}
  font-weight: 500;  
  line-height: 1.16;
  letter-spacing: 0.01px;
  padding-right: 20px;
  ${props => props.centered && `
    text-align: center;
    padding-left: 20px
  `}
`;

export const Footer = styled.div`
  // padding: 24px 25px;
`;

export const InputsContainer = styled.div`
  padding: 40px 0;
`;
export const InputContainer = styled.div`
  // display: flex;
  // align-items: center;
  display: block;
  &:not(:last-child){
    margin-bottom: 40px;
  }
`;
export const Input = styled.input`
  ${props => props.theme.mixins.outline(0)}
  &:focus,&:hover,&:active{
    ${props => props.theme.mixins.outline(0)}
  }
  width: 100%;
  flex: 1 1 auto;
  height: 65px;
  border: none;
  border-bottom: 2px solid #DDDDDD;
  background-color: transparent;
  padding: 15px 0;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0.01px;
  line-height: 43px;
  border-radius: 0;
  ${props => props.invalid && `
    border-color: #FF0023;
  `}
`;
export const Label = styled.label`
  display: none;
  flex: 1 0 100px;
  cursor: pointer;
  padding-right: 10px;
`

export const SubmitButton = styled(Button)`
  font-size: 25px;
  padding: 18px;
  margin-bottom: 24px;  
  &:disabled {
    opacity: 0.5;
  }
`;

SubmitButton.defaultProps = {
  fluid: true,
  inverted: true,
}

export const ErrorMessage = styled.p`
  color: #FF0023;
`

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

const Modal = (props) => {
  const { children, isOpen, onClose, fluid, title, titleCentered, ...rest } = props;
  return (
    <ReactModal
      {...rest}
      onRequestClose={onClose}
      isOpen={isOpen}
      className={{
        base: classnames("modal-content", fluid && 'fluid'),
        afterOpen: 'modal-content_after-open',
        beforeClose: 'modal-content_before-close'
      }}
      overlayClassName={{
        base: 'modal-overlay',
        afterOpen: 'modal-overlay_after-open',
        beforeClose: 'modal-overlay_before-close'
      }}
      closeTimeoutMS={500}
    >
      <GlobalStyle />
      <Content>
        <CloseButton className="close-btn" onClick={onClose}>
          {closeIcon}
        </CloseButton>
        {title && <Header><Title centered={titleCentered}>{title}</Title></Header>}
        {children}
      </Content>
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  fluid: PropTypes.bool
}

export default Modal;
