import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import Stories from './Stories';
import Edcat from 'services/edcat';
import useApiCall from 'hooks/useApiCall';
import { normalizeOptions } from '../utils';
import { get, uniqBy, isNil } from 'lodash';
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { HeaderContext } from 'constants/context';
import { usePrevious } from 'utils/helpers'

const useFilters = (options, defaultSelected) => {
  const [selected, setSelected] = useState(() => new Set(defaultSelected));
  const filters = useMemo(() => (options || []).map((option) => ({ ...option, selected: selected.has(option.value) })), [options, selected]);
  const toggleKeywordsFilter = useCallback(({ value }) => {
    if (selected.has(value)) {
      selected.delete(value);
    } else {
      selected.add(value);
    }
    setSelected(new Set([...selected]));
  }, [selected, setSelected]);
  return [filters, toggleKeywordsFilter, [...selected], setSelected];
}
const StoriesContainer = (props) => {
  const contextValue = useContext(HeaderContext) || {};
  useEffect(() => {
    const { setAlwaysShow } = contextValue;
    setAlwaysShow(true);
    return () => { setAlwaysShow(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isMyStories = get(props, 'match.path') === '/my-stories';
  const defaultKeyword = (new URLSearchParams(get(props, 'location.search'))).get('keyword');
  const [page, setPage] = useState(1);
  const [loadedStories, setLoadedStories] = useState([])
  const [keywords, setKeywords] = useState([]);
  const [types, setTypes] = useState([]);
  const [keywordFilters, toggleKeywordFilters, selectedKeywords] = useFilters(keywords, [defaultKeyword]);
  const [storyTypeFilters, toggleStoryTypeFilters, selectedTypes] = useFilters(types);
  const [count, setCount] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('all')
  //hook used to partially load stories (skip/limit)
  const [loadingReally, setLoading] = useState(true)//removes flicker when select and deselect one keyword
  const { data, component, loading } = useApiCall(() => Edcat.stories.getFilteredList({
    keywords: selectedKeywords,
    types: selectedTypes,
    page,
    filter: statusFilter,
    ...isMyStories && { my: 1 }
  }), [selectedKeywords, selectedTypes, page, statusFilter, isMyStories]);
  //add partially loaded pages to all loaded pages after data will be loaded
  const prev = usePrevious({ loading })
  useEffect(() => {
    if (!loading && isInitialLoading) setIsInitialLoading(false)
    if (loading || !data) return;
    !isNil(data.count) && setCount(data.count);
    data.results && data.results.length && setLoadedStories(loadedStories => uniqBy([...loadedStories, ...data.results], 'slug'));
    data.keywords && data.keywords.length && setKeywords(() => normalizeOptions(data.keywords)) // update keywords
    data.types && data.types.length && setTypes(() => normalizeOptions(data.types)) // update types
  }, [data, isInitialLoading, keywords.length, loading, page, types.length])
  if (prev.loading && !loading && (data.count === count) && loadingReally) setLoading(false); //removes flicker when select and deselect one keyword

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: !!(data || {}).next,
    onLoadMore: () => setPage(page => page + 1),
    scrollContainer: 'window',
    threshold: 500, //px
  });

  const resetData = () => {
    setLoading(true);
    setPage(1);
    setLoadedStories([]);
  }

  if (component && !keywords) return component; //when filters are empty (initial fetch), draw a loading circle
  if (isInitialLoading) return null;
  return (
    <Stories
      loading={loading || loadingReally}
      isMyStories={isMyStories}
      ref={infiniteRef}
      Component={component}
      setPage={setPage}
      types={types}
      count={count}
      keywords={keywords}
      stories={loadedStories}
      keywordFilters={keywordFilters}
      useFilters={useFilters}
      toggleKeywordFilters={(option) => { resetData(); toggleKeywordFilters(option) }}
      storyTypeFilters={storyTypeFilters}
      toggleStoryTypeFilters={(option) => { resetData(); toggleStoryTypeFilters(option) }}
      statusFilter={statusFilter}
      setStatusFilter={(option) => { if (statusFilter !== option) { resetData(); setStatusFilter(option) } }}
    />
  )
}

export default StoriesContainer;
