/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';
import settings from 'settings';

const L = ({ local, ...props }) => {
  if (settings.ABSOLUTE_URL && props.to !== '/' && !local) {
    const { to, ...p } = props;
    return <a href={`${settings.ABSOLUTE_URL}${to}`} {...p} /> 
  } else {
    return <Link {...props}/>
  }
}

export default L;
