import { useMemo, useState, useCallback } from 'react';
import useAsyncApiCall from 'hooks/useAsyncApiCall';
import Edcat from 'services/edcat';
import { LOGIN_MODAL, SIGN_UP_MODAL, CONFIRM_EMAIL_MODAL } from './constants';
import { toast } from 'react-toastify';
import { useStore } from 'store';

export const useModals = () => {
  const [openedModal, setOpenedModal] = useState(null);

  const openLoginModal = useCallback((e) => {
    e && e.preventDefault();
    setOpenedModal(LOGIN_MODAL)
  }, [setOpenedModal]);

  const openSignUpModal = useCallback((e) => {
    e && e.preventDefault();
    setOpenedModal(SIGN_UP_MODAL);
  },[setOpenedModal]);

  const openConfirmEmailModal = useCallback((e) => {
    e && e.preventDefault();
    setOpenedModal(CONFIRM_EMAIL_MODAL);
  },[setOpenedModal]);

  const closeModal = useCallback(() => {
    setOpenedModal(null);
  }, [setOpenedModal]);

  const wrapClose = useCallback(next => async (...args) => {
    await next(...args);
    closeModal();
  }, [closeModal]);
  return {
    openedModal,
    openLoginModal,
    openSignUpModal,
    openConfirmEmailModal,
    wrapClose,
    closeModal,
  }
}

export const useApiHandlers = () => {
  const [login, isLoggingIn] = useAsyncApiCall(async ({ username, password }) => {
    try {
      await Edcat.login({ username, password });
      toast.edcat(`Logged in as ${username}`);
    } catch (error) {
      console.log(error);
      // toast.error(error.message);
      throw error;
    }
  }, []);

  const [logout] = useAsyncApiCall(async () => {
    try {
      await Edcat.logout()
      toast.edcat('Logged out')
    } catch (error) {
      console.log('Logout error', error);
    }
  }, [])

  const [signUp] = useAsyncApiCall(async ({ username, email, password, captcha }) => {
    try {
      await Edcat.signUp({ username, password, captcha, email });
      // toast.success(`Welcome ${username}!`)
    } catch (error) {
      console.log(error);
      // toast.error(error.message);
      throw error;
    }
  }, []);

  return { login, isLoggingIn, logout, signUp };
}

export const useUserMenu = ({ isLoggedIn, openLoginModal, openSignUpModal, logout }) => {
  const [userMenu] = useStore('userMenu');
  return useMemo(() => [
    ...(isLoggedIn ? [] : [
      {
        label: 'Login',
        to: '',
        onClick: (e) => { 
          e && e.preventDefault();
          openLoginModal();
        }
      },
      {
        label: 'Sign Up',
        to: '',
        onClick: (e) => { 
          e && e.preventDefault();
          openSignUpModal();
        }
      },
    ]),
    ...Array.isArray(userMenu) ? userMenu.map(({ name, url }) => ({ label: name, href: url })) : [],
    ...(isLoggedIn ? [
      {
        label: 'Log Out',
        to: '',
        onClick: (e) => { 
          e.preventDefault();
          logout();
        }
      },
    ] : []),
  ], [userMenu, isLoggedIn]);
}