import { globalStore, useStore } from 'use-store-hook';

// export const GLOBAL_NAMESPACE = 'APP';
const store = {
  settings: null,
  cartCount: 0,
  editionsCount: 0,
  user: {
    username: null,
    token: '',
  }
};

Object.entries(store).forEach(([key, value]) => globalStore.set(key, value, { persist: true }));

// globalStore.set(GLOBAL_NAMESPACE, INITIAL_VALUE, { persist: true });

// export const useGlobalStore = ([...args]) => useStore(GLOBAL_NAMESPACE, ...args);
export { useStore };
