import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import last from 'lodash/last';

const fileInputStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  opacity: 0,
  cursor: 'pointer',
  zIndex: -1,
}
const FileInput = ({ value: files, onChange, ...rest }) => {
  const _onChange = useCallback(
    (e) => onChange([...e.target.files]),
    [],
  );
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button className="button" wider style={{ position: 'relative', borderRadius: 0, height: 40 }} as="label">
        <input type="file" style={fileInputStyle} {...rest} onChange={_onChange} />
        select a file
      </Button>
      {files && files.map((file) => {
        const spanStyle = { marginLeft: 15, fontSize: 14 };
        if (!file) return null;
        if (file instanceof File) {
          const { name } = file;
          return (<span key={name} style={spanStyle}>{name}{' '}</span>);
        }
        return (<a key={file} style={spanStyle} rel="noopener noreferrer" target="_blank" href={file}>{last(file.split('/'))}{' '}</a>);
      })}
    </div>
  )
}

FileInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FileInput;
