/* eslint-disable no-nested-ternary */
import { useState, useCallback } from "react";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";

const filterCategoryItems_ = [
  {
    name: "Topics",
    id: "topics",
    type: "filter-category",
    iconAtLeft: false,
    className: "toggle-button",
  },
  {
    name: "Categories",
    id: "category",
    type: "filter-category",
    iconAtLeft: false,
    className: "toggle-button",
  },
  {
    name: "Artists",
    id: "artists",
    type: "filter-category",
    iconAtLeft: false,
    className: "toggle-button",
  },
  {
    name: "Publishers",
    id: "publishers",
    type: "filter-category",
    iconAtLeft: false,
    className: "toggle-button",
  },
];

export const useUserItemsFilters = ({
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [openedFilterCategory, setOpenedFilterCategory] = useState(false);
  const [filtersObject, setFiltersObject] = useState(false);
  const onClickDeSelectedFilter = useCallback(
    ({ id, name }, type) => {
      setOpenedFilterCategory(false);
      setFiltersObject({});
      // eslint-disable-next-line array-bracket-spacing
      setSelectedFilters((/* selectedFilters */) => [
        /* ...selectedFilters, */ { type, id, name },
      ]);
    },
    [setSelectedFilters]
  );
  const onClickSelectedFilter = useCallback(
    ({ id }, type) => {
      setOpenedFilterCategory(false);
      setSelectedFilters((selectedFilters) =>
        selectedFilters.filter(
          (option) => !(option.id === id && option.type === type)
        )
      );
    },
    [setSelectedFilters]
  );
  const onClickFilter = useCallback(
    ({ id, name, isSelected }, type) => {
      if (isSelected) {
        onClickSelectedFilter({ id, name }, type);
        setFiltersObject({});
        return;
      }

      if (type === "filter-category") {
        setOpenedFilterCategory(id);

        setFiltersObject(
          get(find(filters, { type: id }), "values")
        );
        return;
      }

      if (find(selectedFilters, { type, id })) {
        onClickSelectedFilter({ id, name }, type);
        onClickDeSelectedFilter({ id, name }, type);
      } else {
        onClickDeSelectedFilter({ id, name }, type);
      }
    },
    [onClickDeSelectedFilter, onClickSelectedFilter, selectedFilters]
  );

  const setFilterCategory = (option) => {
    setOpenedFilterCategory(option);
  };

  const filterCategoryItems = map(
    [...filterCategoryItems_, ...selectedFilters],
    (i) => {
      const isFilterCategorySelected =
        i.id === openedFilterCategory && i.type === "filter-category";
      return {
        ...i,
        isSelected: isFilterCategorySelected || i.type !== "filter-category",
      };
    }
  );

  return {
    filtersObject,
    openedFilterCategory,
    filterCategoryItems,
    onClickFilter,
    setOpenedFilterCategory,
    onClickDeSelectedFilter,
    onClickSelectedFilter,
    setFilterCategory,
  };
};
