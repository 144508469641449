import React from 'react';
import Select from 'react-select';

const MemoSelect = React.memo(Select);

const selectStyles = {
  option: (styles, { /* data, isDisabled, */isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'transparent',
    // eslint-disable-next-line no-nested-ternary
    color: isSelected ? 'black' : isFocused ? 'black' : '#9a9a9a',
    fontSize: 16,
    marginLeft: 8,
    ':before': {
      display: 'inline-block',
      marginLeft: -15,
      content: isSelected ? 'var(--content-check-icon)' : '" "',
      width: 16,
      height: 0,
    },
    ':active': {},
  }),
};

export const sortOptions = [
  { value: 'latest_added', label: 'latest addition' },
  { value: 'latest_release', label: 'latest release' },
  { value: 'oldest_release', label: 'oldest release' },
  { value: 'lowest_price', label: 'lowest price' },
  { value: 'highest_price', label: 'highest price' },
];

export const SortSelect = ({ value, onChange }) => (
  <MemoSelect
    value={value}
    onChange={onChange}
    options={sortOptions}
    styles={selectStyles}
    isSearchable={false}
  />
);
