import React from 'react';
import { NavLink } from 'components';
import { AccountUI } from '../components';

const MobileNav = ({ items, type, toggle, isOpen }) => {
  return (
    <div className="mobile-nav-container" style={{ display: isOpen ? 'block' : 'none' }}>
      <nav className="mobile-nav" onClick={() => toggle(false)}>
        <ul className="mobile-nav-list">
          {
            items.map(({ path, label, ...rest }, index) => {
              if (rest.type && rest.type !== type) return null
              return (
                <li className="mobile-nav-item" key={path || index}>
                  <NavLink
                    // activeClassName="active"
                    {...rest}
                    className="mobile-nav-link"
                    to={path}
                  >
                    {label}
                  </NavLink>
                </li>
              )
            })
          }
          <AccountUI mobile />
        </ul>
      </nav>
    </div>
  )
}

export default MobileNav;
