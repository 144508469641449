import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Error, Loading, AbsoluteCenter } from 'components';
import { usePrevious } from 'utils/helpers'
import { isEqual } from 'lodash'

export default function useApiCall(call, deps = []) {
  const [salt, setSalt] = useState(true);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetch] = useState(() => () => setSalt(!salt));
  const prev = usePrevious({ deps: [salt, ...deps] });
  const callRef = useRef(call);
  const depsRef = useRef(deps);
  depsRef.current = deps;
  callRef.current = call;

  useEffect(() => {
    if (isEqual(prev.deps, [salt, ...depsRef.current])) { return }
    setIsLoading(true);
    setError(null);
    callRef.current()
      .then(setData)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [prev, salt, ...deps]);

  const component = useMemo(() => {
    if (isLoading) {
      return (
        <AbsoluteCenter>
          <Loading center />
        </AbsoluteCenter>
      )
    }

    if (error) {
      return <Error error={error} retry={refetch} />
    }
    return null;
  }, [isLoading, error, refetch]);

  return {
    data,
    loading: isLoading,
    error,
    refetch,
    component,
  }
}
