import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 17px;
  color: #131413;
  & > label {
    font-weight: normal;
    letter-spacing: -0.13px;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px;
    white-space: nowrap;
  }
  small, .hint {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #939595;
    margin-top: 5px;
    display: inline-block;
  }

`;

const FormControl = ({ children, hint, label, ...rest }) => {
  return (
    <Container {...rest}>
      <label>{label}</label>
      <div>
        {children}
      </div>
      <small>{hint}</small>
    </Container>
  )
}

export default FormControl
