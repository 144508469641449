import React from 'react';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase'
import { useStore } from 'store';
import styled from 'styled-components';

const StatusContainer = styled.div`
display:flex;
>*:first-child{
  margin-right:10px
}
`

const StoryCard = ({
  type,
  title,
  image,
  author,
  //onClick,
  url,
  status,
  listType,
  is_public,
}) => {
  const [user] = useStore('user');
  const u = user || {}
  const showStatus = !!(
    ((listType === 'myStories') || u.is_admin)
    && u
    && listType !== 'relatedStories'
  )
  const showPrivate = !!(
    ((listType === 'myStories') || u.is_admin)
    && !is_public
    && listType !== 'relatedStories'
  )
  return (
    <div className="StoryCard">
      <Link to={url}>
        <div className="story-card-header">
          <div className="type">{type}</div>
          <StatusContainer>
            {showStatus && <div className="status">{startCase(status)}</div>}
            {showPrivate && <div className="status">Private</div>}
          </StatusContainer>
        </div>
        {image && <img src={image} alt={title} className="item-img" />}
        <h1>{title}</h1>
        <div className="author">by <b>{author}</b></div>
      </Link>
    </div>
  )
}

export default StoryCard
