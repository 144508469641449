import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useEventListener from "use-event-listener";
import { Link } from 'components';
import { Touchable as TouchAble } from 'components/styled';
import styled from 'styled-components';

const Container = styled.div`
  position: relative; 
`;
const Touchable = styled(TouchAble)`
  display: flex;

`;

const ButtonText = styled.span`
  padding-right: 22px;
  color: #131413;
  font-size: 24px;
  //letter-spacing: 0.23px;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
`;

const DropDownMenu = styled.div`
  display: none;
  margin: 5px 0 0;
  padding: 5px 0;
  list-style: none;
  background-color: #ffffff;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  min-width: 100%;
  top: 100%;
  right: 0;
  ${props => props.isOpen && `
    display: block;
  `}
`;

const DropDownItem = (Component) => styled(Component)`
  display: block;
  padding: 3px 20px; 
  font-size: 16px;     
  font-weight: lighter;
  line-height: 1.4;
  color: #131413;
  white-space: nowrap;
  //text-decoration: underline;
  text-decoration: none;
`;
const DropDownLink = DropDownItem(Link);
const DropDownSpan = DropDownItem('span');

const Arrow = styled.span`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  ${props => props.isOpen && `
    transform: rotate(180deg);
  `}
`;


const chevronDown = (
  <svg width="18px" height="12px" viewBox="0 0 407.436 407.436">
    <polygon points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 " />
  </svg>
);
const DropDown = (props) => {
  const { label, children, buttonTextStyle = {}, options = [], ...rest } = props;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const toggle = useCallback((val) => setIsOpen(typeof val === 'boolean' ? val : !isOpen), [isOpen]);

  useEventListener('click', (e) => { if (!ref.current.contains(e.target) && isOpen) toggle(false); });

  return (
    <Container ref={ref} {...rest}>
      <Touchable
        onClick={toggle}
        className="dropdown-btn"
      >
        <ButtonText style={buttonTextStyle}>{label}</ButtonText>
        <Arrow isOpen={isOpen}>{chevronDown}</Arrow>
      </Touchable>
      <DropDownMenu isOpen={isOpen}>
        {
          children || options.map(({ label, ...props }) => {
            const Component = (props && props.href) ? DropDownLink : DropDownSpan;
            return (
              <li key={label}><Component {...props} className="dropdown-link">{label}</Component></li>
            )
          })
        }
      </DropDownMenu>
    </Container>
  )
}

DropDown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  }))
}


export default DropDown;