import React, { useMemo } from 'react';
import { Catalog, Filter, Button } from 'components';
import StoryList from '../components/StoryList';
import { PageTitle } from 'components/styled';
import { isEmpty, get } from 'lodash';
import moment from 'moment';
import Vimeo from '@u-wave/react-vimeo';
import { storyPreviewTimeFormat } from '../constants';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useStore } from 'store';
import WaveformSinger from '../WaveformSinger'

const EditStory = styled(Button)`
  ${props => props.marginLeftWidth && css`
    width: ${props.marginLeftWidth};
    margin-left: calc(${props.marginLeftWidth} * -1);
    ${'' /* ${media.lessThan("sm")`
    margin-top: -30px
    `} */}
  `}
`

const StoryPreview = ({ history, story }) => {
  const {
    type,
    title,
    author,
    image,
    created,
    changed,
    keywords,
    text,
    featuredItems,
    items,
    slug,
    relatedStories,
    vimeo_id,
    entities,
    related_event,
    audio_url,
    waveform_data,
    can_edit } = story;

  const [settings] = useStore('settings');
  const userProfileUrl = useMemo(() => (get(settings, 'user_profile_url') || '').replace('TEMPLATE', author), [author, settings]);
  const showRelatedSection = related_event || (entities && entities.length !== 0);

  let mediaBlock = '';
  if (vimeo_id) {
    mediaBlock = <Vimeo
        color="#000"
        loop
        showTitle={false}
        showPortrait={false}
        video={vimeo_id}
        volume={1}
        autoplay
        responsive
    />
  } else if (audio_url && waveform_data) {
    mediaBlock = <WaveformSinger story={story} />
  } else if (image) {
    mediaBlock = <img src={image} alt={title} />
  }

  return (
    <div className="StoryPreview">
      <div className="text-center story-label"><b>{type}</b>
        {
          can_edit &&
          <Link to={`/stories/edit/${slug}`}>
            <EditStory className="float-right" marginLeftWidth="100px" unrolled>edit</EditStory>
          </Link>
        }
      </div>
      <div className="story">
        <div className="header">
          <PageTitle style={{ marginBottom: '0.2em', fontFamily: 'baskerville' }}>{title.split('\n').map(line => (<span key={line}>{line}<br /></span>))}</PageTitle>
          <div className="subline">
            <div style={{ whiteSpace: 'nowrap' }}>by <a href={userProfileUrl}><b>{author}</b></a></div>
            <div>{`created ${moment.unix(created).format(storyPreviewTimeFormat)}, last changed ${moment.unix(changed).format(storyPreviewTimeFormat)}`}</div>
          </div>

          <div className="videoContainer">
            { mediaBlock }
          </div>

          {Array.isArray(keywords) && <Filter disabled link="/stories?keyword=" filters={keywords} />}
          {showRelatedSection && <div className="related-to">
            <div>
              <p>Related to</p>
            </div>
            <div>
              {related_event && <a href={related_event.edcat_url}>{related_event.value}</a>}
              {entities && entities.map((item, i) => <a  style={{ display: 'inline-block' }} href={item.edcat_url}>{item.value}{i !== entities.length-1 ? ',':''}&nbsp;</a>)}
            </div>
          </div>}
        </div>
        <div className="content">
          <div>{text}</div>
        </div>
      </div>
      {featuredItems && featuredItems.length > 0 && <Catalog items={featuredItems} xl />}
      {items  && items.length > 0&& <Catalog items={items} />}
      {
        !isEmpty(relatedStories) && (
          <div className="related-stories">
            <StoryList listType="relatedStories" stories={relatedStories} title="Related Stories" />
          </div>
        )
      }
    </div>
  )
}

export default StoryPreview
