import React, { Component } from 'react';
import Publishers from './Publishers';

export default class PublishersContainer extends Component {
  render() {
    return (
      <Publishers {...this.props} />
    )
  }
}
