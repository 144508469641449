import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import styled from 'styled-components';
import { media } from 'theme';

const color = '#590F96';
const StyledRadio = styled.label`
  ${'' /* margin: 0 8px 0 0; */}
  cursor: pointer;
  input {
    &[type="radio"] {
      display: none;
      &:checked + span {
        &:before {
          background-color: ${color};
          box-shadow: inset white 0 0 0 3px;
        }
      }
    }
  }
  span {
    display: flex;
    color: #3E4752;
    &:before {
      border: 1px solid ${color};
      content: '';
      display: flex;
      height: 19px;
      width: 19px;
      margin-right: 12px;
      border-radius: 50%;
      box-sizing: border-box;
      transition: all ease .200s;
      box-shadow: inset white 0 0 0 10px;
    }
  }
  ${media.greaterThan('md')`
    &:not(:last-child){
      span{
        margin-right: 30px;
      }
    }`
  }
`;

const RadioGroup = styled.div`
  display: flex;
  ${media.lessThan('md')`
    flex-direction: column;
    > label:not(:first-child) {
      margin-top:10px; 
    }
  `}
`;
const Radio = ({ value: currentValue, onChange, options, name, ...rest }) => {
  const { current: _name } = useRef(uuid());
  const _onChange = useCallback(
    (e) => onChange(e.target.value),
    [onChange],
  );
  return (
    <RadioGroup>
      {
        options.map(({ label, value }) => (
          <StyledRadio key={value}>
            <input type="radio" {...rest} name={name || _name} onChange={_onChange} value={value} {...(currentValue === value) && { checked: "checked" }} />
            <span>{' '}{label}</span>
          </StyledRadio>
        ))
      }
    </RadioGroup>
  )
}

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Radio;
