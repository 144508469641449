import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const EmailConfirmationSuccess = () => {
  React.useEffect(() => {
    toast.edcat('Your Account was activated');
  }, []);

  return (
    <Redirect to="/" />
  )
}

export default EmailConfirmationSuccess
