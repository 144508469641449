import React, { useCallback } from 'react'
import { Modal } from 'components';
import styled from 'styled-components';
import { Button } from 'components';
import useAsyncApiCall from 'hooks/useAsyncApiCall';
import Edcat from 'services/edcat';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: fit-content;
`;
const marginRight20px = { marginRight: 20 }

export const DeleteStoryModal = (props) => {
  const { isOpen, title, slug, closeModal } = props;
  const history = useHistory()
  const [deleteStory, isDeleting] = useAsyncApiCall(async () => {
    try {
      await Edcat.stories.delete({ slug });
      toast.edcat(`Successfully deleted`);
      history.push(`/stories`);
    } catch (error) {
      console.error(error);
      toast.edcat('Unknown error');
    }
  }, []);
  const close = useCallback(() => { isDeleting || closeModal() }, [closeModal, isDeleting])
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      titleCentered
    >
      <p style={marginRight20px}>Are you sure you want to delete the story {title}?</p>
      <ButtonsContainer>
        <Button
          link
          noHoverEffects
          type="button"
          gray
          onClick={close}
          disabled={isDeleting}
        >Cancel</Button>
        <Button
          link
          noHoverEffects
          type="button"
          gray
          onClick={deleteStory}
          disabled={isDeleting}
        >Yes, delete</Button>
      </ButtonsContainer>
    </Modal>
  )

}
export default DeleteStoryModal