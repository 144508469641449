import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Highlighter from "react-highlight-words";

const Select = (props) => {
  const { Component = ReactSelect, options, onChange, showSeparator = false, showChevron = true, formatCreateLabel, boldOptions, ...rest } = props;

  function formatOptionLabel({ label, __isNew__ }, { inputValue, context }) {
    if (context !== 'menu') return label; //Select use this format even to format selected options
    const HighlightedText = <Highlighter
      searchWords={[inputValue]}
      textToHighlight={__isNew__ ? inputValue : label} //to not highlight 'create label' text in options
      highlightStyle={{ fontWeight: 'normal', backgroundColor: 'inherit', color: 'inherit' }}
    />;
    return (__isNew__ && formatCreateLabel) ? formatCreateLabel(HighlightedText) : HighlightedText; //to not highlight 'create label' text in options
  }

  const _onChange = useCallback((v) => onChange(v), [onChange]);
  return (
    <Component
      options={options}
      onChange={_onChange}
      styles={{
        control: (s) => ({ ...s, height: 48, }),
        indicatorSeparator: (s) => showSeparator ? s : {},
        dropdownIndicator: (s) => showChevron ? s : { display: 'none' },
        placeholder: (s) => ({ ...s, color: '#A8A9A9', fontSize: 18 }),
        singleValue: (s) => ({ ...s, fontSize: 18 }),
        multiValue: (s) => ({ ...s, padding: 5, marginRight: 4, marginLeft: 4, borderRadius: 6, backgroundColor: '#D8D8D8' }),
        multiValueLabel: (s) => ({ ...s, fontSize: 14 }),
        multiValueRemove: (s) => ({ ...s, color: '#A8A9A9' }),
        menu: (s) => ({ ...s, marginTop: 0 }),
        menuList: (s) => ({ ...s, paddingBottom: 9, paddingTop: 9 }),
        option: (s) => {
          const isHighlighted = (s.backgroundColor === '#DEEBFF');
          return ({
            ...s,
            fontSize: 18,
            backgroundColor: isHighlighted ? '#DFEFED' : 'transparent',
            color: isHighlighted ? '#00C491' : 'inherit',
            ':active': { ...s[':active'], backgroundColor: '#DFEFED' },
            '&:hover': { backgroundColor: '#DFEFED' }
          });
        },
      }}
      {...boldOptions && { formatOptionLabel }}
      createOptionPosition="first"
      {...rest}
    // {...control}
    />
  )
}

Select.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.node.isRequired,
  }))
}

export default Select;
