import React, { useCallback, useMemo } from 'react';
import { Filter } from 'components';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { media } from 'theme';
import classnames from 'classnames';
import { CloseButton, CloseIcon } from 'components/styled';
import find from 'lodash/find';
import { CreateStoryLink } from '../Stories';
import { Link } from 'react-router-dom';
// import useBreakpoint from 'hooks/useBreakpoint';
// import ReactSelect from 'react-select'
import { Select as ReactSelect } from 'components/Form';
import every from 'lodash/every';

const options = [
  { label: 'all stories', value: 'all' },
  { label: 'public', value: 'public' },
  { label: 'private', value: 'private' },
  { label: 'status: featured', value: 'featured' },
  { label: 'status: draft', value: 'draft' },
  { label: 'status: live', value: 'live' },
  { label: 'status: flagged', value: 'flagged' },
  { label: 'status: archived', value: 'archived' },
]

const MyStoriesLinkStyled = styled(Link)`
  ${media.lessThan("md")`
    display: none;
  `}
`
const MyStoriesLink = ({ children }) => <MyStoriesLinkStyled to="/my-stories">{children}</MyStoriesLinkStyled>;
const myStoriesFilter = {
  value: '__MY_STORIES__',
  label: 'my stories',
  style: { border: 'none' },
  inverted: false,
  noHoverEffects: true,
  Wrapper: MyStoriesLink,
};
const FilterContainer = styled.div`
  margin-bottom: 25px;
`;
const FiltersContainer = styled.div`
  margin-bottom: 57px;
  ${media.lessThan("md")`
    margin-bottom: 0px;
  `}
`;
const TogglableWrapper = styled(FiltersContainer)`
  margin-bottom:0px;
  height: 100%; /* used to remove flicker at first fixed render due to error in first calc because of margin in child div*/
  ${media.lessThan("md")`
    ${props => props.showFilters || `
      display: none;
    `}
  `}
`
const CloseAllFiltersButton = styled(CloseButton)`
  margin-top: -13px;
  margin-right: 8px;
  ${media.greaterThan("md")`
    display: none;
  `}
`
const StoriesCount = styled.span`
  min-width: 110px;
  margin-right: -10px;
`
const SortByContainer = styled.div`
  ${'' /* margin-left: auto; */}
  ${'' /* margin-top: 8px; */}
`
const SidesContainer = styled.div`
  display: flex;
`
const Label = styled.span`
flex: 1;
font-family: Baskerville;
font-size: 31px;
display: contents;
`
const FiltersWrapper = styled.div`
flex: 4;
`
const Select = styled(ReactSelect)`
min-width: 200px;
margin-bottom: auto;
margin-top: -3px;
div[class*="control"] {
  border:none;
  box-shadow:none;
}
&>div>div>div{
  font-size:14.4px;
}
span[class*="indicatorSeparator"] {
  display:none;
}
div[class*="placeholder"]::before,
div[class*="singleValue"]::before {
  content: "filter by ";
}`

// const unSelectedFilterWithMyStories = (f) => !f.selected;
const unSelectedFilter = { selected: false };
// const selectedFilter = { selected: true };

export const FiltersContainerTogglable = ({
  showFilters,
  setShowFilters,
  isMyStories,
  allFilters,
  toggleKeywordFilters,
  keywordFilters,
  toggleStoryTypeFilters,
  count,
  storyTypeFilters,
  isSticky,
  pageTitle,
  statusFilter,
  setStatusFilter,
  user,
}) => {
  // const isSelectedFilters = useMemo(() => find(allFilters, selectedFilter), [allFilters]);
  const storyTypeFiltersWithMyStories = useMemo(() => [...storyTypeFilters, !isMyStories && user && myStoriesFilter].filter(Boolean), [isMyStories, storyTypeFilters, user])
  // const isSelectedStoryTypeFiltersWithMyStories = useMemo(() => find(storyTypeFiltersWithMyStories, selectedFilter), [storyTypeFiltersWithMyStories]);
  const isDeSelectedAllStoryTypeFilters = useMemo(() => every(storyTypeFilters, unSelectedFilter), [storyTypeFilters]);
  // const onToggleSelected = option => find(keywordFilters, { value: option.value }) ? toggleKeywordFilters(option) : toggleStoryTypeFilters(option);
  const closeAllFilters = useCallback(() => setShowFilters(false), [setShowFilters])
  const storiesCountPluralized = useMemo(() => pluralize('story', count, true), [count])
  // const { minWidth } = useBreakpoint();
  // const keywordFilterIsNotEmpty = useMemo(() => find(keywordFilters, unSelectedFilter), [keywordFilters])
  // const storyTypesFilterIsNotEmpty = useMemo(() => find(storyTypeFiltersWithMyStories, (minWidth < 768) ? unSelectedFilter : unSelectedFilterWithMyStories), [minWidth, storyTypeFiltersWithMyStories])
  const isAdminUser = user && user.is_admin;
  return (
    <TogglableWrapper showFilters={showFilters}>
      <SidesContainer>
        {isSticky && <Label>{pageTitle}</Label>}
        <FiltersWrapper>
          <CloseAllFiltersButton onClick={closeAllFilters}><CloseIcon /></CloseAllFiltersButton>
          {/* {isSelectedFilters && <FilterContainer className={classnames('selected-filters', 'filters', { 'my-stories': isMyStories })} style={{ ...isMyStories && { display: 'flex' } }}>
            <Filter
              onToggle={onToggleSelected}
              filters={allFilters}
              showOnlySelected
              showCloseIcon
              noHoverEffects
              label="Selected"
            />
          </FilterContainer>} */}
          {!isMyStories /*&& keywordFilterIsNotEmpty*/ &&
            <FilterContainer className="filters">
              <Filter
                onToggle={toggleKeywordFilters}
                filters={keywordFilters}
                // hideSelected
                showFirst={2}
                showAllLabel="all keywords"
                // displayAllLabelOnMobile
                showSortSelected
                label="Story keywords"
                showAllProps={{ noHoverEffects: true }}
                showCloseIcon
                hideCloseIconWhenDeSelected
                closeAfterSelected
              />
            </FilterContainer>
          }
          <div className={classnames('story-type-filters-container', { 'my-stories': isMyStories })}>
            {isMyStories && <StoriesCount className="stories-count">{storiesCountPluralized}</StoriesCount>}
            {/*storyTypesFilterIsNotEmpty && */<FilterContainer className="story-type-filters filters">
              <Filter
                onToggle={toggleStoryTypeFilters}
                filters={storyTypeFiltersWithMyStories}
                inverted={isDeSelectedAllStoryTypeFilters}
                showCloseIcon={!isDeSelectedAllStoryTypeFilters}
                hideCloseIconWhenDeSelected
                // hideSelected
                label="Story types"
              />
            </FilterContainer>}
            {isAdminUser && <SortByContainer className="sort-by-container">
              <Select
                options={options}
                value={find(options, { value: statusFilter })}
                onChange={option => setStatusFilter(option.value)}
              />
            </SortByContainer>}
          </div>
        </FiltersWrapper>
        {isSticky && <CreateStoryLink style={{ flex: 1, maxWidth: 150, minWidth: 150 }} buttonStyle={{ height: 'auto' }}></CreateStoryLink>}
      </SidesContainer>
    </TogglableWrapper>
  )
}
export default FiltersContainerTogglable;
