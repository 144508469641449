import React, { useEffect, useState } from 'react';
import { Header, Footer, Loading, AbsoluteCenter } from 'components';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import settings from 'settings';
import 'react-toastify/dist/ReactToastify.min.css';
import Edcat from 'services/edcat';
import { useStore } from 'store';
import { createGlobalStyle } from 'styled-components';
import useEmailActivationToast from 'hooks/useEmailActivationToast';
import { HeaderContext } from 'constants/context'
import { StickyContainer } from 'react-sticky';
import { useLocation } from 'react-router-dom';

const { SEO = {} } = settings || {};

const GlobalStyle = createGlobalStyle`
  .App {
    .Toastify__close-button { display: none; }
    .Toastify__toast--default {
      color: #000;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    }
    .Toastify__progress-bar--default {
      background: #000;
    }
  }
`;

const MainLayout = ({ children, ...rest }) => {
  let location = useLocation();
  const pathname = `${location.pathname}`
  let [seo, setSeo] = useState({})
  useEffect(() => {
    Edcat.seoTags(pathname).then(tags => setSeo(tags));
  }, [pathname])
  const { title, description, image } = seo;

  return (
    <div className="App">
      <Helmet
        title={`${title || SEO.title}`} //used props instead children https://github.com/nfl/react-helmet/issues/373
        meta={[
          { charSet: "utf-8", },
          { property: "description", content: description || SEO.description },
          { property: "og:title", content: title || SEO.title },
          { property: "og:description", content: description || SEO.description },
          { property: "og:image", content: image || SEO.image },
          { name: "thumbnail", content: image || SEO.image },
        ]}
      />
      <App {...rest}>
        {children}
      </App>
      <ToastContainer />
      <GlobalStyle />
    </div>
  );
}

// Register API callback bindings to react to user changes
const useStartup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useStore('user');
  const [, setSettings] = useStore('settings');
  const [, setCartCount] = useStore('cartCount');
  const [, setEditionsCount] = useStore('editionsCount');
  const [, setUserMenu] = useStore('userMenu');

  useEffect(() => {
    Edcat.onLogin(({ username, token, items_in_cart, total_items, is_admin, settings, is_story_admin }) => {
      setUser({ username, token, is_admin, is_story_admin });
      setCartCount(items_in_cart || 0);
    });
    Edcat.onLogout(() => {
      setUser(null);
      setCartCount(0);
    });
    Edcat.onUserMenuUpdated((menuItems) => {
      setUserMenu(Array.isArray(menuItems) ? menuItems : []);
    });
    Edcat.resume(user ? user.token : null)
      .then((data) => {
        setSettings(data.settings);
        setCartCount(data.items_in_cart || 0);
        setEditionsCount(data.total_items || 0);
      })
      .catch((error) => {
        console.log(error);
        console.log('User has been logged out by server.')
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isLoading, setIsLoading];
}

const App = ({ children, ...rest }) => {
  const [isLoading] = useStartup();
  useEmailActivationToast();

  if (isLoading) {
    return (
      <AbsoluteCenter>
        <Loading center />
      </AbsoluteCenter>
    )
  }

  return (
    <React.Fragment>
      <Header {...rest} />
      <StickyContainer>
        <main className="main">
          {children}
        </main>
      </StickyContainer>
      <Footer  {...rest} />
    </React.Fragment>
  )
}
const WithHeaderContext = (props) => { const [alwaysShow, setAlwaysShow] = useState(false); return (<HeaderContext.Provider value={{ alwaysShow, setAlwaysShow }}> <MainLayout {...props} /></HeaderContext.Provider>) }
export default WithHeaderContext;
