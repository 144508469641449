import React, { useCallback } from 'react';
import Home from './Home';
import Edcat from 'services/edcat';
import useApiCall from 'hooks/useApiCall';
import { Error, Loading, AbsoluteCenter } from 'components';
import { useStore } from 'store';

export default function HomeContainer(props) {
  const { data, error, loading, refetch } = useApiCall(() => Edcat.home())
  const [settings] = useStore('settings');
  const onSubmitSearch = useCallback(
    (query) => window.location.href = `${settings ? settings.search_url : '/'}?q=${query}`,
    [settings],
  );


  if (loading) {
    return (
      <AbsoluteCenter>
        <Loading center/>
      </AbsoluteCenter>
    )
  }

  if (error) {
    return <Error error={error} retry={refetch} />
  }

  return (
    <Home      
      {...props}
      categories={data.categories}
      bottomSectionHtml={data.bottom_section.content}
      totalItems={data.total_items}
      items={data.featured_editions}
      featuredEvent={data.featured_event}
      // searchUrl={data.settings.search_url}
      allPublicationsUrl={settings && settings.all_publications_url}
      allArtistsUrl={settings && settings.all_artists_url}
      allPublishersUrl={settings && settings.all_publishers_url}
      totalArtists={data.total_artists}
      totalPublishers={data.total_publishers}
      onSubmitSearch={onSubmitSearch}
    />
  )
}
