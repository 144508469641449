import React from 'react';

const Item = ({ url, image, title, subtitle, price }) => {

  
  const formatArtistNames=(subtitle)=>{
    let artistArr=subtitle.split(',');
    return(      
      artistArr.length>3 ? (
        `${artistArr.slice(0,3).join(',')}\u00A0+${artistArr.length - 3}`
        )
      : subtitle
    )
  }

  return (    
    <div className="Item">
      <a href={url} className="Link item-link">
        <div className="item-img-container">
          <img src={image} alt={title} className="item-img"/>
        </div>
        <div className="item-text">
          <div className="item-title">{title}</div>
          {
            subtitle ? (
              <div className="item-artists"> 
                {formatArtistNames(subtitle)}
              </div>
            )
            : null
          }
          {price ? <div className="price-container"><div className="item-price">{price}</div></div> : null}
        </div>
      </a>
    </div>
  );
}
export default Item;

// import { Link }  from 'react-router-dom';

// const Item = (props)=>{
//   const { params, item, isArtistDetails } = props;   
//   const {image, artists, title} = item;
              
//   return ( 
//     <div className="Item">
//       <Link className="item-link" to={'ItemDetails'} params={params}>
//         <div className="item-img-container">
//           {image&&<img className="item-img" src={image} alt="img"/>}
//         </div>
//         <div className="item-text">                
//           {
//             isArtistDetails&&(
//               artists&&(artists.length > 0) ?
//               (
//                 artists.map((props, i) => {
//                   return (
//                     <span className="item-artists" key={i}>
//                       {artists[i].name}
//                     </span>
//                   )
//                 })
//               ):(                
//                 item.author&&(item.author.length>0) ? (
//                   <span className="item-artists">
//                     {item.author}
//                   </span>
//                 ):(
//                   item.editor&&(item.editor.length>0)&&
//                   <span className="item-artists">
//                     {item.editor}
//                   </span>
//                 )
//               )
//             )
//           }               
//           {title&&<span className="item-title">
//             {title}
//           </span>}
//         </div>
//       </Link>
//     </div>
//   )
// } 
