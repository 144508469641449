import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Textarea = styled.textarea`
  padding: 10px;
  height: 300px;
  border: 1px solid #CDD3DA;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 100%;
  resize: none;
  color: #131413;
  font-size: 18px;
  letter-spacing: 0.03px;
  line-height: 21px;
  font-weight: 300;
`;

const TextArea = ({ value, onChange, ...rest }) => {
  const _onChange = useCallback(
    (e) => onChange(e.target.value),
    [],
  );
  return (
    <Textarea {...rest} onChange={_onChange} value={value}>
      {value}
    </Textarea>
  )
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextArea;
