import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledInput = styled.input`
  padding: 13px;
  border: 1px solid #CDD3DA;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 100%;
  color: #131413;
  font-size: 18px;
  letter-spacing: 0.03px;
  line-height: 21px;
`;


const Input = ({ value, type = "text", onChange, ...rest }) => {
  const _onChange = useCallback(
    (e) => onChange(e.target.value),
    [],
  );
  return (
    <StyledInput {...rest} type={type} onChange={_onChange} value={value} />
  )
}

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Input;
