import React, { Component } from 'react';
import Artists from './Artists';

export default class ArtistsContainer extends Component {
  render() {
    return (
      <Artists {...this.props} />
    )
  }
}
