/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DropDown, Link } from 'components';

const preventDefault = (e) => {
  if(e) {
    e.preventDefault();
    e.stopPropagation();
  }
}

const AccountUI = ({
  username,
  links,
  isLoggedIn,
  mobile,
}) => {
  return (
    <React.Fragment>
      {mobile ? (
        <React.Fragment>
          {isLoggedIn ? (
            <li className="mobile-nav-item" onClick={preventDefault}>
              <b className="mobile-nav-link">{username}</b>
            </li>
          ) : null}
          {links.map(({ label, ...rest }) => (
            <li className="mobile-nav-item" key={label}>
              <Link {...rest} className="mobile-nav-link">
                {label}
              </Link>
            </li>
          ))}
        </React.Fragment>
      ) : isLoggedIn ? (
        <DropDown label={username} options={links} />
      ) : (
        <React.Fragment>
          {links.map(({ label, ...rest }) => (
            <Link {...rest} className="login-link" key={label}>
              {label}
            </Link>
          ))}
        </React.Fragment>
      )}
      
    </React.Fragment>
  );
}; 

export default AccountUI;