import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'theme';
import useReactRouter from 'use-react-router';


const Container = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 5px;
  border-radius: 2px;
  ${media.lessThan('lg')`
    padding: 0 12px;
  `}
  ${media.lessThan('sm')`
    padding: 0;
  `}
`;

const Submit = styled.button`
  ${props => props.theme.mixins.outline(0)}
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  padding: 10px 5px 10px 10px;;
  
  ${media.lessThan('lg')`
    transform: scale(0.8);
    order: 1;
  `}
  ${media.greaterThan('xl')`
    margin-right: 20px;
  `}
  .icon{
    display: block;
    ${media.lessThan('sm')`
      width: 18px;
    `}
  } 
`;

const Input = styled.input`
  background: transparent;
  height: 48px;
  width: 100%;   
  margin: 0;
  padding: 0 6px;
  line-height: 1.4;
  letter-spacing: 0.2px;
  font-family: $global-font-family;    
  color: #000000;
  border: none;    
  ${props => props.theme.mixins.outline(0)} 
  font-size: 32px;

  ${media.lessThan('sm')`
    padding-left: 0;
    padding-right: 5px;
    margin-bottom: 10px;
    font-size: 22px;
  `}

  ${props => props.xl ? media.lessThan('xl')`
    display: none !important;
  ` : media.greaterThan('xl')`
    display: none !important;
  `}
`;

const Magnifier = () => (
  <svg className="icon" width="26px" height="26px" viewBox="0 0 17 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-219.000000, -39.000000)" stroke="#FFFFFF" strokeWidth="0.1" fill="#96989E">
        <g transform="translate(216.000000, 36.000000)">
          <path d="M14.5584227,15.1707741 C13.4450565,16.1542476 11.9791244,16.7480429 10.3832995,16.7480429 C6.8576399,16.7480429 4,13.890403 4,10.3647434 C4,6.83908379 6.8576399,4 10.3832995,4 C13.890403,4 16.7480429,6.83908379 16.7480429,10.3647434 C16.7480429,11.9605683 16.1542476,13.4265004 15.1893302,14.5584227 L19.9025805,19.2716729 C20.0324732,19.4015657 20.0324732,19.4943462 19.9025805,19.6242389 L19.6242389,19.9025805 C19.4943462,20.0324732 19.4201218,20.0324732 19.2902291,19.9025805 L14.5584227,15.1707741 Z M10.3832995,4.87213685 C7.34009858,4.87213685 4.89069295,7.34009858 4.89069295,10.3647434 C4.89069295,13.3893882 7.34009858,15.85735 10.3832995,15.85735 C13.4079443,15.85735 15.85735,13.3893882 15.85735,10.3647434 C15.85735,7.34009858 13.4079443,4.87213685 10.3832995,4.87213685 Z" id="search-icon"></path>
        </g>
      </g>
    </g>
  </svg>
);

// Where el is the DOM element you'd like to test for visibility
function isHidden(el) {
  return (el.offsetParent === null)
}

const Search = ({ query: initialQuery = '', onSubmit, placeholderXl, ...rest }) => {
  const [query, setQuery] = useState(initialQuery); 
  const { location, history } = useReactRouter();
  // const scrollAnchor = React.useRef();
  const searchInput = React.useRef();
  const searchInputMobile = React.useRef();

  React.useEffect(() => {
    if (location.hash === '#search-input') {
      history.replace({ ...location, hash: '' });
      
      // scrollAnchor.current && scrollAnchor.current.scrollIntoView({
      //   behavior: 'smooth'
      // });

      if (!isHidden(searchInput.current)) {
        searchInput.current && searchInput.current.focus();
      } else {
        searchInputMobile.current && searchInputMobile.current.focus();
      }
    }
  }, [location.hash]);

  return (
    <Container onSubmit={(e) => { e.preventDefault(); query && onSubmit(query); }}>
      {/* <div ref={scrollAnchor} style={{ position:'absolute', top: -96, visibility: 'hidden' }}></div> */}
      <Submit type="submit">            
        <Magnifier />           
      </Submit>  
      <Input
        ref={searchInput}
        {...rest}
        xl
        type="text"            
        name='search'
        placeholder={placeholderXl}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        autoFocus
      />
      <Input 
        ref={searchInputMobile}
        {...rest}
        type="text"            
        name='search'
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        placeholder="Search for art editions"
      />      
    </Container>
  )
} 

export default Search;
