import { useRef, useEffect } from 'react'

export const displayNumber = (n) => n.toLocaleString();

export function usePrevious(value, initialValue = {}) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}