import React from 'react';
// import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
// import settings from "settings";

//console.log(settings)

// function transform(node, index) {
//   if (node.type === "tag" && node.name === "a") {
//     if (node.attribs && node.attribs.href) {
//       node.attribs.href = `${settings.ABSOLUTE_URL}${node.attribs.href}`;
//       return convertNodeToElement(node, index, transform);
//     }
//   }
// }

// const RenderHtml = ({ html }) =>
//   settings.ABSOLUTE_URL ? (
//     <div className="text">{ReactHtmlParser(html, { transform })}</div>
//   ) : (
//     <div className="text" dangerouslySetInnerHTML={{ __html: html }} />
//   );

const RenderHtml = ({ html }) => <div className="text" dangerouslySetInnerHTML={{ __html: html }} />

export default RenderHtml;
