import React from "react";
import { Button } from "components";

const ErrorComponent = ({ error, retry }) => {
  return (
    <div>
      Error: {typeof error === "string" ? error : error.message}
      {retry ? (
        <div>
          <Button onClick={retry}>Retry</Button>
        </div>
      ) : null}
    </div>
  );
};

export default ErrorComponent;
