import { useState, useCallback } from 'react';

export default function useAsyncCall(cb, deps) {
  const [loading, setLoading] = useState(false);
  
  const callback = useCallback(
    async (...args) => {
      try {
        setLoading(true);
        await cb(...args);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    deps,
  );

  return [callback, loading];
}
