import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

///////////////////////////
// TOAST CUSTOMIZATION
///////////////////////////
const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#61b86d" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>

const ToastContainer = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
`;
const Icon = styled.span`
  margin: 0 7px;
`;
Icon.defaultProps = { height: 20, alt: '' }
export const withCheck = (text) => (
  <ToastContainer><Icon>{checkIcon}</Icon> {text}</ToastContainer>
);

toast.edcat = (message, options = {}, ...rest) => {
  return toast(withCheck(message), { autoClose: 3000, ...options }, ...rest);
};
///////////////////////////
// TOAST CUSTOMIZATION END
///////////////////////////
