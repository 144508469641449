import React from 'react';
import { Search, Catalog, RenderHtml, Event, FadeIn, Button, Filter } from 'components';
import { A } from 'components/styled';
import { displayNumber } from 'utils/helpers';
import styled from 'styled-components';
import { media } from 'theme';

const FiltersContainer = styled.div`
  ${media.greaterThan('xl')`
    display: flex;
  `}
  margin-bottom: 50px;
`;

const FiltersButtonContainer = styled.div`
  ${media.greaterThan('xl')`
    flex: 0 0 auto;
    margin-left: 50px;
  `}
`;

const FiltersSectionContainer = styled.div`
  ${media.greaterThan('md')`
    margin-bottom: 35px;
  `}
`;

const HomeSectionTitle = styled.h3`
  font-size: 30px;
  ${media.lessThan('sm')`
    font-size: 22px;
  `}
  font-weight: lighter;
  line-height: 48px;
  letter-spacing: 0.38px;
  margin-bottom: 10px;   
  ${media.greaterThan('md')`
    font-size: 32px;
    margin-bottom: 20px; 
  `} 
`;

const SearchContainer = styled.div`
  margin-top: 10px;
  ${media.greaterThan('md')`
    margin-top: 44px;
    margin-bottom: 44px;
  `} 
`;

const HomeContainer = styled.div`
  padding: 0;
`;

const Home = ({
  bottomSectionHtml, items, categories, allPublicationsUrl, onSubmitSearch, totalItems, featuredEvent,
  allArtistsUrl, allPublishersUrl, totalArtists, totalPublishers,
}) => {  
  return (
    <HomeContainer className="Home">
    
      { 
        featuredEvent && (
          <FadeIn duration={700}>
            <Event         
              btnText="Find out more"
              {...featuredEvent}
            />
          </FadeIn>
        )
      }
      
      <FadeIn duration={700}>
        <SearchContainer>
          <Search
            onSubmit={onSubmitSearch}
            placeholderXl={`Search within ${displayNumber(totalItems)} artists' books, editions, monographs and ephemera`}
          />
        </SearchContainer>
      </FadeIn>

      <FadeIn duration={700}>
        <FiltersSectionContainer>
          <HomeSectionTitle>Browse editions by category</HomeSectionTitle>
          <FiltersContainer>
            <Filter
              filters={categories.map(c => ({ ...c, label: c.data.display_name, value: c.data.url }))}
              onToggle={({ value }) => window.location.href = value}
            />
            <FiltersButtonContainer>
              <A href={allPublicationsUrl} noUnderline><Button round small inverted wider bold>Show all publications</Button></A>
            </FiltersButtonContainer>
          </FiltersContainer>
        </FiltersSectionContainer>
      </FadeIn>
    
      
      <div>
        <HomeSectionTitle>featured editions</HomeSectionTitle>
        {/*<h3><a href={allPublicationsUrl}>see all</a></h3>*/}
        <Catalog items={items}/>
      </div>

      <div className="text" style={{marginBottom: 90}}>
        <h1><A href={allPublicationsUrl}>Show all publications {totalItems ? `(${totalItems})` : null}</A></h1>
        <h1><A href={allArtistsUrl}>Show all artists {totalArtists ? `(${totalArtists})` : null}</A></h1>
        <h1><A href={allPublishersUrl}>Show all publishers {totalPublishers ? `(${totalPublishers})` : null}</A></h1>
      </div>
      <RenderHtml html={bottomSectionHtml} />
      {/*<div className="text" dangerouslySetInnerHTML={{__html:bottomSectionHtml}} />*/}

    </HomeContainer>
  );
}

export default Home;
