import React from 'react';
import ItemCard from './ItemCard';
import { SectionTitle } from 'components/styled';

const ItemList = ({ itemClassName, items, title, listType, featuredItem, externalUrl }) => {
  return (
    <div className="ItemList">
      {title ? (
        <SectionTitle>
          {title}
        </SectionTitle>
      ) : null}
      <div className="list">
        {items.map((Item, i) => <ItemCard  externalUrl={externalUrl} itemClassName={itemClassName} featuredItem={featuredItem} index={i} listType={listType} slug={Item.slug} key={Item.slug} {...Item} url={Item.url} />)}
      </div>
    </div>
  )
}

export default ItemList;
