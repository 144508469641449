/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import qs from 'query-string';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';

export default () => {
  const { history, location } = useReactRouter();
  useEffect(() => {
    if (!location.search) return;
    const { activation } = qs.parse(location.search);
    if (activation && activation === 'true') {
      window.setTimeout(() => {
        toast.edcat('Your Account was activated');
      }, 300);
      history.replace({
        ...location,
        search: '',
      });
    }
  }, [location.search]);
};
