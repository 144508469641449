import React from "react";

const { REACT_APP_ABSOLUTE_URL } = process.env;
const ItemCard = ({
  index,
  title,
  image,
  featured_image,
  currency,
  price,
  url,
  person,
  featuredItem,
  itemClassName,
  externalUrl,
  slug,
}) => {
  const featuredItemList =
    window.innerWidth < 800
      ? [0, 1, 16, 17, 32, 33]
      : [0, 1, 11, 12, 22, 23, 33, 34];
  return (
    <div className={itemClassName || (featuredItem ? "ItemCard" : "ItemCard2")}>
      <a href={externalUrl ? `${REACT_APP_ABSOLUTE_URL}/stories/${slug}` : url}>
        {image && (
          <img
            src={
              featuredItem && featuredItemList.includes(index)
                ? featured_image || image
                : image
            }
            alt={title}
            className="item-img"
          />
        )}
        <div>
          {featuredItem && featuredItemList.includes(index) ? (
            <h1>
              {person}: {title}
            </h1>
          ) : (
            <>
              <h1>{person}</h1>
              <h1>{title}</h1>
            </>
          )}

          {price && <div className="price">{`${currency} ${price}`}</div>}
        </div>
      </a>
    </div>
  );
};

export default ItemCard;
