import React from 'react';
import EditionItem from './EditionItem';
import styled from 'styled-components';

const EditionItemWithHover = styled(EditionItem)`
  &:hover {
    background-color: #DFEFED;
    color: #0025FF;
  }
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  border-bottom: none;
`;

const EditionOption = ({ data, isSelected, innerProps, innerRef }) => {
  if (isSelected) return null;
  return (
    <EditionItemWithHover ref={innerRef} {...innerProps} {...data}/>
  );
}

export default EditionOption;
