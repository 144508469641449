import React from "react";
import { Select } from "components/Form";
import EditionOption from "./EditionOption";
import EditionItem from "./EditionItem";
import pullAt from 'lodash/pullAt';
import debouncePromise from 'debounce-promise'
// const handleChangeSelect = ({ onChange, value }) => (option) => {
//   console.log(value);
//   option && onChange(value ? cloneDeep([...value, option]) : cloneDeep([{option}]));
// };
function move(array, fromIndex, toIndex) {
  const clone = [...array];
  clone.splice(toIndex, 0, clone.splice(fromIndex, 1)[0]);
  return clone;
}

const EditionsSelect = ({ onChange, hint, value, loadOptions = () => { }, ...props }) => {
  const onRemove = React.useCallback((index) => {
    const cloned = [...value];
    pullAt(cloned, index)
    onChange(cloned)
  }, [value, onChange]);

  const onReorder = React.useCallback((prev, next) => onChange(move(value, prev, next)), [value, onChange]);

  return (
    <div>
      <Select
        {...props}
        components={{ Option: EditionOption }}
        isClearable={false}
        loadOptions={debouncePromise((props) => loadOptions(props, value), 500, { leading: false })}
        value=""
        onChange={option => onChange(prev => [...prev, option])}
      />
      <small>{hint}</small>
      {Array.isArray(value) &&
        value.map((option, index) => (
          <EditionItem
            key={option.value}
            {...option}
            onReorder={(newOrder) => onReorder(index, newOrder)}
            onRemove={() => onRemove(index)}
            order={index}
            lastIndex={value.length - 1}
            outsideOfMenu
          />
        ))}
    </div>
  );
};

export default EditionsSelect;
