import React from 'react';
import styled from 'styled-components';
import chevronUpIcon from 'assets/icons/Chevron-up.svg';
import chevronDownIcon from 'assets/icons/Chevron-down.svg';
import closeIcon from 'assets/icons/Close.svg';
import { media } from 'theme';

const ICON_SIZE = 16;
const Container = styled.div``;

const Divider = styled.div`
  height: 1px;
  background: #CDD3DA;
  margin-right: 7px;
`; //margin as in invisionApp

const Option = styled.div`
  display: flex;
  padding: 12px 0;
  ${'' /* border-bottom: 1px solid #CDD3DA; */}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0;
  ${media.lessThan("md")`
    span {
      word-break: break-all;
    }`
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-right: 0px;
  ${media.lessThan("md")`
    flex-direction: column;
  `}
`;

const ImgContainer = styled.div`
  display: flex;
  margin: auto;
`;

const SpacedSpan = styled.span`
  line-height: 24px;
  margin-top: -3px;
  margin-bottom: -5px;
`;

const Control = styled.button`
  display:flex;
  justify-content: center;
  align-items: center;
  height: ${ICON_SIZE * 2}px;
  width: ${ICON_SIZE * 2}px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s transform ease-in-out;
  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
  &:hover:not(:disabled) {
    transform: scale(1.2);
  }
`;

Control.defaultProps = { type: 'button' };


const EditionItem = React.forwardRef(function EditionItem(props, ref) {
  const { image, title, subtitle, order, onReorder, onRemove, lastIndex, edition, release_year, publisher, outsideOfMenu, ...rest } = props;
  return (
    <Container>
      <Option {...rest} ref={ref} style={{ ...outsideOfMenu && { fontSize: 14 } }}>
        <Row style={{ flex: 44 }}>
          <ImgContainer style={{ flex: 7 }}>
            <img height={42} src={image} alt={title} style={{ margin: 'auto' }} />
          </ImgContainer>
          <Column style={{ marginLeft: 20, flex: 37 }}>
            <span>
              {title}
            </span>
            {subtitle && <span>
              by {subtitle}
            </span>}
          </Column>
        </Row>
        <Column style={{ marginLeft: 20, flex: 26 }}>
          <SpacedSpan>
            {publisher}
          </SpacedSpan>
        </Column>
        <Row style={{ flex: 14 }}>
          <Column style={{ textAlign: "right" }}>
            {edition &&
              <span>
                {edition}
              </span>
            }
            <span>
              {release_year}
            </span>
          </Column>
        </Row>
        <Row style={{ flex: 14 }}>
          <Controls>
            {onReorder && (
              <React.Fragment>
                <Control onClick={() => onReorder(order - 1)} disabled={order === 0}>
                  <img height={ICON_SIZE} src={chevronUpIcon} alt={'Up'} style={{ opacity: (order === 0) ? 0.3 : 0.8 }} />
                </Control>
                <Control onClick={() => onReorder(order + 1)} disabled={order === lastIndex}>
                  <img height={ICON_SIZE} src={chevronDownIcon} alt={'Down'} style={{ opacity: (order === lastIndex) ? 0.3 : 0.8 }} />
                </Control>
              </React.Fragment>
            )}
            {onRemove && (
              <Control onClick={onRemove}>
                <img height={ICON_SIZE * 1.3} src={closeIcon} alt={'Remove'} />
              </Control>
            )}
          </Controls>
        </Row>
      </Option>
      <Divider />
    </Container>
  );
});

export default EditionItem;


