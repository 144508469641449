import React, { useState, useCallback } from 'react';
import { Link } from 'components';
import { Footer, InputsContainer, InputContainer, Input, Label, SubmitButton, ErrorMessage } from 'components/Modal';
import { Form } from './SignUpForm';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const INITIAL_INVALID_INPUTS = {};
const makeAllFieldsInvalid = fields => fields.reduce((o, f) => {
  o[f] = true;
  return o;
}, {})

export const useFormHandlers = ({
  onSubmit,
  fields,
  values,
}) => {
  const [invalidInputs, setInvalidInputs] = useState(INITIAL_INVALID_INPUTS);
  const isFieldValid = useCallback((field) => !invalidInputs[field], [invalidInputs]);
  const getFieldError = useCallback((field) => invalidInputs[field], [invalidInputs]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const form = {};
  fields.forEach((field) => {
    const [value, setValue] = useState(values[field]);
    const handler = useCallback((value) => {
      setValue(value);
      setInvalidInputs({ ...invalidInputs, [field]: null });
    }, [setValue, invalidInputs, setInvalidInputs]);
    form[field] = { value, handler, error: getFieldError(field) };
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    try {
      const formValues = fields.reduce((fv, field) => {
        fv[field] = (form[field] || {}).value;
        return fv;
      }, {});
      await onSubmit(formValues);
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : error.error || error.message;

      const errorMessagesByFields = get(error, 'raw.response.data', {});
      const newInvalidInputs = fields.reduce((map, field) => {
        if (errorMessagesByFields[field]) { map[field] = errorMessagesByFields[field]; }
        return map;
      }, {});
      if (isEmpty(newInvalidInputs)) {
        setError(errorMessage);
        setInvalidInputs(makeAllFieldsInvalid(fields));
      } else {
        setInvalidInputs(newInvalidInputs);
      }

    } finally {
      setIsSubmitting(false);
    }
  });

  return { handleSubmit, isFieldValid, getFieldError, isSubmitting, error, form };
}

const values = { username: '', password: ''};
const fields = Object.keys(values);

const LoginForm = ({ onSubmit, openSignUpModal }) => {
  const {
    form,
    handleSubmit,
    isFieldValid,
    isSubmitting,
    error,
  } = useFormHandlers({
    onSubmit,
    fields,
    values,
  });

  const mandatoryFieldsFilled = form.username.value && form.password.value;

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <InputsContainer>
        <ErrorMessage>
          {error}
        </ErrorMessage>
          <InputContainer>
            <Label htmlFor="username">Username</Label>
            <Input
              className="input"
              id="username"
              type="text"
              name="username"
              required
              onChange={(e) => form.username.handler(e.target.value)}
              placeholder="Username"
              invalid={!isFieldValid('username')}
            />
          </InputContainer>
          {!isFieldValid('username') ? <ErrorMessage>{form.username.error}</ErrorMessage> : null }
          <InputContainer>
            <Label htmlFor="password">Password</Label>
            <Input
              className="input"
              id="password"
              type="password"
              name="password"
              required
              onChange={(e) => form.password.handler(e.target.value)}
              placeholder="Password"
              invalid={!isFieldValid('password')}
            />
            {!isFieldValid('password') ? <ErrorMessage>{form.password.error}</ErrorMessage> : null }
          </InputContainer>
        </InputsContainer>
        <Footer>
          <p>
            <SubmitButton className="modal-btn" type="submit" disabled={isSubmitting || !mandatoryFieldsFilled}>Login</SubmitButton>
          </p>
          <p>
            <Link to="/forgot-password/">Forgot Password?</Link>
          </p>
          <p>
            Don't have an account? <Link to="" onClick={openSignUpModal}>Sign Up!</Link>
          </p>
        </Footer>
      </Form>
    </React.Fragment>
  )
}

export default LoginForm
