import axios from 'axios';
import { API_URL, MOCK_API, DEBUG } from 'settings';
import isEmpty from 'lodash/isEmpty';

// const delay = (ms = 1500) => new Promise((resolve,reject)=>setTimeout(resolve, ms));
class Edcat {
  baseUrl = API_URL

  logoutListeners = []

  loginListeners = []

  userMenuListeners = []

  token = null

  _authorized = (next) => {
    if (!this.token) {
      throw new Error('Not authorized');
    }
    next();
  }

  _call = async ({ method = "get", path, headers = {}, params, data, ...rest }) => {
    DEBUG && console.log(`[Edcat] Calling ${this.baseUrl}${path}`, { headers, params, data, rest });
    try {
      if (MOCK_API) {
        DEBUG && console.log(`[Edcat] Return mocked response ${this.baseUrl}${path}`);
        const mocks = await import('./mocks');
        return mocks.edcat[`${method}.${path}`];
      }

      if (this.token) {
        Object.assign(headers, {
          Authorization: `Token ${this.token}`,
        });
      }
      // await delay(1500);
      const res = await axios.request({
        url: `${this.baseUrl}${path}`,
        method,
        headers,
        params,
        data,
        ...rest,
      });
      DEBUG && console.log(`[Edcat] Success ${this.baseUrl}${path}`, { res });
      return res.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.logout();
      }
      DEBUG && console.log(`[Edcat] Error ${this.baseUrl}${path}`, { error });
      const errorMessage = error.response ? error.response.data.error || error.message : error.message;
      const err = new Error(errorMessage);
      err.raw = error;
      throw err;
    }
  }

  _clearToken = () => {
    this.token = null;
  }

  _logout = () => {
    this._clearToken();
    this.logoutListeners.forEach((cb) => cb());
  }

  resume = async (resumeToken) => {
    this.token = resumeToken;
    const data = await this._call({ path: '/settings/' });
    const { user, items_in_cart, total_items, user_menu = [] } = data;

    this.userMenuListeners.forEach((cb) => cb(user_menu));

    if (user) {
      this._onAfterLogin({ ...user, items_in_cart, total_items });
    } else {
      this._logout();
    }

    return data;
  }

  logout = () => {
    this._call({ path: '/logout/', method: 'post' })
      .catch((error) => console.log('Error logging out', error));

    this.resume();
  }

  seoTags = (path) => { return this._call({ path: `/seo/?path=${path}`, method: 'get' }) }

  home = async () => {
    return await this._call({ path: '/home/' });
  }

  _onAfterLogin = (user) => {
    this.token = user.token;
    this.loginListeners.forEach((cb) => cb(user));
  }

  login = async ({ username, password }) => {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);
    bodyFormData.set('password', password);

    const data = await this._call({
      path: '/sign-in/',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'content-type': 'multipart/form-data',
      },
      data: bodyFormData,
    });

    const { user } = data;
    this.resume(user.token);

    return user;
  }

  signUp = async ({ username, password, email, captcha }) => {
    var bodyFormData = new FormData();
    bodyFormData.set('username', username);
    bodyFormData.set('password', password);
    bodyFormData.set('email', email);
    bodyFormData.set('g-recaptcha-response', captcha);

    const data = await this._call({
      path: '/sign-up/',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'content-type': 'multipart/form-data',
      },
      data: bodyFormData,
    });

    const { user } = data;
    this.resume(user.token);

    return data;
  }

  onLogout = cb => this.logoutListeners.push(cb)

  onLogin = cb => this.loginListeners.push(cb)

  onUserMenuUpdated = cb => this.userMenuListeners.push(cb)

  formToData = (params) => {
    const bodyFormData = new FormData();
    Object.entries(params).forEach(([key, v]) => {
      let value = v;
      if (value instanceof File) {
        bodyFormData.append(key, value, value.name);
        return;
      }
      if (Array.isArray(value)) {
        value = value.join(',');
      }
      if (isEmpty(value) && value !== true && value !== false) return;
      bodyFormData.append(key, value);
    });
    return bodyFormData;
  }
  stories = {
    getTypes: () => this._call({ path: '/stories/form/types/' }),
    getList: () => this._call({ path: '/stories/list/' }),
    getFilteredList: ({ keywords, types, limit, offset, filter, page, my }) => this._call({
      path: '/stories/list/', params: {
        keywords: Array.isArray(keywords) ? keywords.join(',') : keywords,
        types: Array.isArray(types) ? types.join(',') : types,
        limit, offset, page,
        filter: filter,
        my,
      }
    }),
    getMy: () => this._call({ path: `/stories/list/my/` }),
    getOne: slug => this._call({ path: `/stories/view/${slug}/` }),
    getKeywordSuggestions: query => this._call({ path: '/stories/keywords/suggestions/', params: { query } }),
    getEntitiesSuggestions: query => this._call({ path: '/stories/entities/suggestions/', params: { query } }),
    getEventSuggestions: query => this._call({ path: '/stories/events/suggestions/', params: { query } }),
    getItemSuggestions: ({ query, uuid }) => this._call({ path: '/stories/items/suggestions/', params: { query, uuid } }),
    delete: ({ slug }) => {
      return this._call({
        path: `/stories/delete/${slug}/`,
        method: 'post',
      });
    },
    create: (params) => {
      return this._call({
        path: '/stories/create/',
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'content-type': 'multipart/form-data',
        },
        data: this.formToData(params),
      });
    },
    edit: (slug, params) => {
      return this._call({
        path: `/stories/edit/${slug}/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'content-type': 'multipart/form-data',
        },
        data: this.formToData(params),
      });
    },
  }
  user = ({ username }) => this._call({ path: `/users/${username}/` });
  userItems = ({ page, filter, username, sort }) =>
    this._call({
      path: `/users/${username}/items/`,
      params: {
        page,
        [filter.type]: filter.value,
        sort,
        is_featured:(filter.value || filter.type === 'all')?false: true
      },
    });

  shopItems = ({ page, filter, username }) =>
    this._call({
      path: `/users/${username}/${filter}/`,
      params: {
        page,
      },
    });
  // async auth(token) {
  //   this.token = token;
  // }
}
export default new Edcat();

