import React from 'react';
import StoryCard from './StoryCard';
import { SectionTitle } from 'components/styled';

const StoryList = ({ stories, title, listType }) => {
  return (
    <div className="StoryList">
      {title ? (
        <SectionTitle>
          {title}
        </SectionTitle>
      ) : null}
      <div className="list">
        {stories.map((story) => <StoryCard listType={listType} key={story.slug} {...story} url={`/stories/${story.slug}`} />)}
      </div>
    </div>
  )
}

export default StoryList;
