import React, { useState, useCallback } from 'react';
import { Select, Input, Radio, TextArea, FileInput, ButtonSwitch } from 'components/Form';
import { FormControl, Button } from 'components';
import { toast } from 'react-toastify';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import differenceBy from 'lodash/differenceBy';
import Edcat from 'services/edcat';
import EditionsSelect from './EditionsSelect';
import { normalizeOptions } from '../utils';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatable from 'react-select/lib/AsyncCreatable';
import styled from 'styled-components';
import { media } from 'theme';
import DeleteStoryModal from './DeleteStoryModal';
import debouncePromise from 'debounce-promise'

const ButtonsContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  ${media.lessThan("md")`
    flex-direction: column-reverse;
    >*:first-child{
      margin-top:20px;
    }
  `}
`
const TypeStatusRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.greaterThan("md")`
    align-items:center;
  `}
  > *:not(:first-child) {
    margin-left:30px
    ${media.lessThan("md")`
      margin-left: 10px;
    `}
  }
`

export const statusOptions = [
  { label: 'hidden', value: 'draft' },
  { label: 'live', value: 'live' },
  { label: 'flagged', value: 'flagged' },
  { label: 'archived', value: 'archived' },
  { label: 'featured', value: 'featured' },
]

const formSchema = {
  type: {
    label: 'Story Type',
    hint: 'Choose the type of story',
    placeholder: 'select',
    required: true,
    loadOptions: async () => {
      try {
        const storyTypesRes = await Edcat.stories.getTypes();
        return normalizeOptions(storyTypesRes ? storyTypesRes.results : []);
      } catch (error) {
        console.warn(error);
      }
    }
  },
  status: {
    label: 'Status',
    hint: 'Choose the status',
    placeholder: 'select',
    options: statusOptions,
  },
  visibility: {
    label: '',
    options: [{ label: 'Public', value: 'public' }, { label: 'Private', value: 'private' }, { label: 'Draft', value: 'draft' }],
    defaultValue: true,
    required: true,
  },
  title: {
    label: 'Story Title',
    hint: 'Please enter the main title of the story',
    placeholder: 'Story Title',
    required: true,
    maxLength: 70,
  },
  slug: {
    label: 'Slug',
    hint: 'Enter the main keywords with - between',
    required: false,
    placeholder: 'optional',
    maxLength: 30,
    customRegEx: /^[a-zA-Z0-9-]+$/,
  },
  text: {
    label: 'Text',
    hint: 'The subtitle of short description, limited to 2400 characters',
    placeholder: 'Write something exciting for this story',
    required: false,
    maxLength: 2400,
  },
  image: {
    label: 'Images',
    multiple: false,
    accept: 'image/*',
  },
  keywords: {
    label: 'Keywords',
    placeholder: 'Add Tags',
    hint: 'Type the first letters of a keyword and choose them from the list or create a new tag',
    loadOptions: async (query) => {
      try {
        const res = await Edcat.stories.getKeywordSuggestions(query);
        return normalizeOptions(res ? res.results : []);
      } catch (error) {
        console.warn(error);
      }
    },
    formatCreateLabel: userInput => <span>{userInput} (create this keyword)</span>,
    isClearable: false,
    showChevron: false,
    boldOptions: true,
  },
  entities: {
    label: 'Related artists, publishers or other entities',
    placeholder: 'Type the name of an entity',
    hint: 'Type the first letter of an entity and choose them from the list',
    loadOptions: async (query) => {
      try {
        const res = await Edcat.stories.getEntitiesSuggestions(query);
        return normalizeOptions(res ? res.results : []);
      } catch (error) {
        console.warn(error);
      }
    },
    isClearable: false,
    showChevron: false,
    boldOptions: true,
  },
  related_event: {
    label: 'Related event',
    placeholder: 'Chose a related event',
    hint: 'Optionally you can connect the story to an existing event',
    loadOptions: async (query) => {
      try {
        const res = await Edcat.stories.getEventSuggestions(query);
        return [{ value: "", label: "⠀" }].concat(normalizeOptions(res ? res.results : []));
      } catch (error) {
        console.warn(error);
      }
    },
    isClearable: false,
    showChevron: true,
    boldOptions: true,
  },
  items: {
    label: 'Listed Editions',
    placeholder: 'Search for an edition to add it to this story',
    hint: 'Enter the title, artist or publisher',
    // options: relatedBooks.map((item) => ({...item, value: item.title, label: item.title })),
    defaultValue: [],
    loadOptions: async (query, value) => {
      try {
        const res = await Edcat.stories.getItemSuggestions({ query });
        const normalizedOptions = normalizeOptions(res ? res.results : []);
        return differenceBy(normalizedOptions, value, 'value');
      } catch (error) {
        console.warn(error);
      }
    },
    noOptionsMessage: ({ inputValue }) => {
      if (inputValue === '') return null;
      return 'No options found matching your query'
    },
    showChevron: false,
  },
  is_highlighted: {
    label: 'Highlight',
    defaultValue: false,
    required: true,
  },
  vimeo_id: {
    label: 'Vimeo Video ID',
    hint: 'To include a video, please provide the video ID',
    placeholder: '123456789',
    required: false,
    maxLength: 40,
  },
  simplecast_episode_id: {
    label: 'Simplecast Episode ID',
    hint: 'To include a podcast, please provide the Simplecast Episode ID',
    placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
    required: false,
    maxLength: 255,
  },
  audio_url: {
    label: 'Audio File URL',
    hint: 'To include a audio, please provide the audio URL',
    placeholder: 'https://...',
    required: false,
    maxLength: 255,
  },
  subtitle: {
    label: 'Subtitle',
    hint: '',
    placeholder: 'optional',
    required: false,
    maxLength: 255,
  },
  website: {
    label: 'Related Website',
    hint: 'To include a weblink, please provide the URL',
    placeholder: 'https://',
    required: false,
    maxLength: 255,
  },
  publishing_date: {
    label: 'Publishing Date (UTC)',
    hint: '',
    placeholder: '01 / 04 / 2022, 12:30 PM',
    required: false,
    type: 'datetime-local',
  },
};

const useForm = (schema, values) => Object.entries(schema).reduce((state, [key, descriptor]) => {
  let defaultValue = values ? values[key] : undefined;
  if (typeof defaultValue === 'undefined') {
    defaultValue = typeof descriptor.defaultValue !== 'undefined' ? descriptor.defaultValue : '';
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [val, set] = useState(defaultValue);

  return {
    ...state,
    [key]: { value: val, set },
  }
}, {});

const extractValues = (controls) => Object.entries(controls).reduce((formValues, [key, { value }]) => ({ ...formValues, [key]: value }), {});
const validate = (values, schema) => {
  Object.entries(schema).forEach(([key, schema]) => {
    const value = values[key];
    if (schema.required) {
      const isNull = isNil(value);
      const isEmpty = typeof value === 'string' && value.length === 0;
      if (isNull || isEmpty) throw new Error(`${schema.label || key} is required.`)
    }
  });
}

const selectOptionToValue = ({ value, id }) => value || id;
const selectOptionsToValue = (options) => Array.isArray(options) ? options.map(selectOptionToValue) : [];

const transformValues = (formValues) => ({
  ...formValues,
  items: selectOptionsToValue(formValues.items),
  keywords: selectOptionsToValue(formValues.keywords),
  related_event: formValues.related_event ? formValues.related_event.value: null,
  entities: selectOptionsToValue(formValues.entities),
  type: selectOptionToValue(formValues.type),
  status: selectOptionToValue(formValues.status),
  image: formValues.image && formValues.image[0] && (formValues.image[0] instanceof File) ? formValues.image[0] : null,
});

const StoryForm = ({ values, onSubmit, isAdmin, userUsername, isStoryAdmin }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMassages, setErrorMassages] = useState(null);
  const formControls = useForm(formSchema, values);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const rawFormValues = extractValues(formControls);
      const formValues = transformValues(rawFormValues);
      validate(formValues, formSchema);
      await onSubmit(formValues);
    } catch (error) {
      toast.error("Please correct form errors.");
      setErrorMassages(error.raw.response.data)
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit, formControls])

  const [isOpen, setIsOpen] = useState(false);
  const closeDeleteModal = useCallback(() => setIsOpen(false), [])
  const openDeleteModal = useCallback(() => setIsOpen(true), [])

  let errorMassagesDisplay = <></>;
  if (errorMassages) {
    const errorItems = Object.entries(errorMassages).map(([key, value]) => <li>{key}: {value}</li>);
    errorMassagesDisplay = <>
      <p className="errors-message">Form errors:</p>
      <ul>{errorItems}</ul>
    </>
  }
  return (
    <form className="story-form" onSubmit={handleSubmit}>
      {errorMassages && errorMassagesDisplay}
      <TypeStatusRowContainer>
        <FormControl label={formSchema.type.label} hint={formSchema.type.hint} style={{ width: 300 }}>
          <Select
            name="type"
            value={formControls.type.value}
            onChange={formControls.type.set}
            Component={AsyncSelect}
            cacheOptions
            defaultOptions
            isSearchable={false}
            {...formSchema.type}
          />
        </FormControl>
        {isAdmin && <FormControl label={formSchema.status.label} hint={formSchema.status.hint} style={{ width: 300 }}>
          <Select
            name="status"
            value={formControls.status.value}
            onChange={formControls.status.set}
            Component={Select}
            cacheOptions
            defaultOptions
            isSearchable={false}
            {...formSchema.status}
          />
        </FormControl>}
        <FormControl label={formSchema.visibility.label} hint={formSchema.visibility.hint}>
          <Radio
            name="visibility"
            value={formControls.visibility.value}
            onChange={formControls.visibility.set}
            {...omit(formSchema.visibility, ['defaultValue'])}
          />
        </FormControl>
      </TypeStatusRowContainer>
      <TypeStatusRowContainer>
        <FormControl label={formSchema.title.label} hint={formSchema.title.hint} style={{ width: 500 }}>
          <Input
            name="title"
            value={formControls.title.value}
            onChange={(value) => value.length <= formSchema.title.maxLength && formControls.title.set(value)}
            maxLength={formSchema.title.maxLength}
            {...formSchema.title}
          />
        </FormControl>
        <FormControl label={formSchema.slug.label} hint={formSchema.slug.hint} style={{ width: 300 }}>
          <Input
            name="slug"
            value={formControls.slug.value}
            onChange={(value) => (formSchema.slug.customRegEx.test(value) || value === '') &&
              (value.length <= formSchema.slug.maxLength)
              ? formControls.slug.set(value)
              : formControls.slug.set(value.substr(0, formSchema.slug.maxLength))
            }
            maxLength={formSchema.slug.maxLength}
            {...formSchema.slug}
          />
        </FormControl>
      </TypeStatusRowContainer>

      <TypeStatusRowContainer>
        <FormControl label={formSchema.subtitle.label} hint={formSchema.subtitle.hint} style={{ width: 500 }}>
          <Input
              name="subtitle"
              value={formControls.subtitle.value}
              onChange={(value) => value.length <= formSchema.subtitle.maxLength && formControls.subtitle.set(value)}
              maxLength={formSchema.subtitle.maxLength}
              {...formSchema.subtitle}
          />
        </FormControl>
        {isStoryAdmin && (<>
          <FormControl label={formSchema.publishing_date.label} hint={formSchema.publishing_date.hint} style={{ width: 300 }}>
            <Input
                name="subtitle"
                onChange={formControls.publishing_date.set}
                value={formControls.publishing_date.value}
                {...formSchema.publishing_date}
            />
          </FormControl>
        </>)}
      </TypeStatusRowContainer>

      <FormControl label={formSchema.text.label} hint={formSchema.text.hint}>
        <TextArea
          name="text"
          value={formControls.text.value}
          onChange={formControls.text.set}
          maxLength={formSchema.text.maxLength}
          {...formSchema.text}
        />
      </FormControl>
      <TypeStatusRowContainer>
        <FormControl label={formSchema.image.label} hint={formSchema.image.hint} style={{ width: 400 }}>
          <FileInput
              name="image"
              value={formControls.image.value}
              onChange={formControls.image.set}
              {...formSchema.image}
          />
        </FormControl>
        <FormControl label={formSchema.vimeo_id.label} hint={formSchema.vimeo_id.hint} style={{ width: 400 }}>
          <Input
              name="vimeo_id"
              value={formControls.vimeo_id.value}
              onChange={(value) => value.length <= formSchema.vimeo_id.maxLength && formControls.vimeo_id.set(value)}
              maxLength={formSchema.vimeo_id.maxLength}
              {...formSchema.vimeo_id}
          />
        </FormControl>
      </TypeStatusRowContainer>
      <TypeStatusRowContainer>
        <FormControl label={formSchema.simplecast_episode_id.label} hint={formSchema.simplecast_episode_id.hint} style={{ width: 400 }}>
          <Input
              name="simplecast_episode_id"
              value={formControls.simplecast_episode_id.value}
              onChange={(value) => value.length <= formSchema.simplecast_episode_id.maxLength && formControls.simplecast_episode_id.set(value)}
              maxLength={formSchema.simplecast_episode_id.maxLength}
              {...formSchema.simplecast_episode_id}
          />
        </FormControl>
        <FormControl label={formSchema.audio_url.label} hint={formSchema.audio_url.hint} style={{ width: 400 }}>
          <Input
              name="audio_url"
              value={formControls.audio_url.value}
              onChange={(value) => value.length <= formSchema.audio_url.maxLength && formControls.audio_url.set(value)}
              maxLength={formSchema.audio_url.maxLength}
              {...formSchema.audio_url}
          />
        </FormControl>
      </TypeStatusRowContainer>
      <TypeStatusRowContainer>
        <FormControl label={formSchema.related_event.label} hint={formSchema.related_event.hint} style={{ width: 400 }}>
          <Select
              Component={AsyncCreatable}
              name="related_event"
              value={formControls.related_event.value}
              onChange={formControls.related_event.set}
              defaultOptions
              cacheOptions
              {...formSchema.related_event}
          />
        </FormControl>
        <FormControl label={formSchema.website.label} hint={formSchema.website.hint} style={{ width: 400 }}>
          <Input
              name="website"
              value={formControls.website.value}
              onChange={(value) => value.length <= formSchema.website.maxLength && formControls.website.set(value)}
              maxLength={formSchema.website.maxLength}
              {...formSchema.website}
          />
        </FormControl>
      </TypeStatusRowContainer>
      <FormControl label={formSchema.entities.label} hint={formSchema.entities.hint}>
        <Select
            Component={AsyncCreatable}
            name="entities"
            isMulti
            value={formControls.entities.value}
            onChange={formControls.entities.set}
            defaultOptions
            cacheOptions
            {...formSchema.entities}
            loadOptions={debouncePromise((props) => formSchema.entities.loadOptions(props), 500, { leading: false })}
        />
      </FormControl>
      <FormControl label={formSchema.keywords.label} hint={formSchema.keywords.hint}>
        <Select
          Component={AsyncCreatable}
          name="keywords"
          isMulti
          value={formControls.keywords.value}
          onChange={formControls.keywords.set}
          defaultOptions
          cacheOptions
          {...formSchema.keywords}
          loadOptions={debouncePromise((props) => formSchema.keywords.loadOptions(props), 500, { leading: false })}
        />
      </FormControl>
      <FormControl className="editions-select" label={formSchema.items.label}>
        <EditionsSelect
          name="items"
          value={formControls.items.value}
          onChange={formControls.items.set}
          Component={AsyncSelect}
          {...formSchema.items}
        />
      </FormControl>
      <ButtonsContainer>
        <Button tag={'button'} superWide small type="submit" className="button" style={{ fontWeight: 'normal', fontSize: 14, letterSpacing: 0.5 }} inverted disabled={isSubmitting}>
          Save
      </Button>
        <div style={{ display: 'flex' }}>
          {(isAdmin || userUsername === values.author) && values.slug &&
            <>
              <Button
                link
                noHoverEffects
                type="button"
                gray
                style={{ marginRight: 35 }}
                onClick={openDeleteModal}
              >
                delete story
            </Button>
              <DeleteStoryModal slug={values.slug} title={values.title} isOpen={isOpen} closeModal={closeDeleteModal} />
            </>
          }
          {isAdmin &&
            <ButtonSwitch
              name="is_highlighted"
              value={formControls.is_highlighted.value}
              onChange={formControls.is_highlighted.set}
              label={formSchema.is_highlighted.label}
              {...formSchema.is_highlighted}
            />
          }
        </div>
      </ButtonsContainer>
    </form>
  )
}

export default StoryForm;
