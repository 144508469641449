import React, { useCallback, useState, useMemo, useEffect } from 'react';
import StoryForm from '../components/StoryForm';
import FormPage from '../components/FormPage';
import Edcat from 'services/edcat';
import { useStore } from 'store';
import AccessDeniedMessage from '../components/AccessDeniedMessage'
import { get, map } from 'lodash'

const CreateStory = ({ history }) => {
  const { location: { search } } = history;
  const params = new URLSearchParams(search);
  const uuid = params.get('uuid');
  const [preLoadedItems, setPreLoadedItems] = useState()
  useEffect(() => { uuid && Edcat.stories.getItemSuggestions({ uuid }).then((res) => setPreLoadedItems((res && res.results) || [])) }, [uuid]);
  const [user] = useStore('user');
  const onSubmit = useCallback(async (formData) => {
    const response = await Edcat.stories.create(formData);
    history.push(`/stories/${get(response, 'slug')}`);
  }, [history]);
  const values = useMemo(() => ({
    ...preLoadedItems && Array.isArray(preLoadedItems) && { items: map(preLoadedItems, ({ key, ...keyword }) => ({ ...keyword, value: keyword.key })) },
    visibility: 'draft',
  }), [preLoadedItems])
  if (uuid && !preLoadedItems) return null

  if (!user) return <AccessDeniedMessage />;

  return (
    <FormPage
      title="Create a new story"
    >
      <StoryForm values={values} onSubmit={onSubmit} />
    </FormPage>
  )
}

export default CreateStory
