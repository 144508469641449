import { Button } from 'components';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import styled, { css } from 'styled-components';
import { media } from 'theme';
const StyledButton = styled(Button)`
  border-color: hsl(0,0%,80%);
  color: hsl(0,0%,40%);
  &:focus{
    background-color: white;
    color:black;
    color: hsl(0,0%,40%);
  }
  &:hover{
    background-color: black;
    color:white;
  }
  ${media.lessThan("md")`
    &:hover{
    background-color: white;
    color:black;
    color: hsl(0,0%,40%);
  }
  `}
  ${props => props.isSelected && css`
    background-color: black;
    color:white;
    &:focus,&:hover,&:active{
      background-color: black;
      color:white;
    }
  `}
`

const ButtonSwitch = ({ value, onChange, options, name, label, ...rest }) => {
  const { current: _name } = useRef(uuid());
  const _onChange = useCallback(() => { onChange(!value) }, [value, onChange]);

  const isSelected = value === true;
  return (
    <StyledButton type="button" wider unrolled small key={name || _name} onClick={_onChange} isSelected={isSelected}>{label}</StyledButton>
  )
}

ButtonSwitch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ButtonSwitch;
