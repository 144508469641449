/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { NavLink } from 'react-router-dom';
import settings from 'settings';
import { theme } from 'theme';

const cursorPointer = { cursor: 'pointer' }
//NavLink
const L = ({ local, ...props }) => {
  if (!props.to) return <div style={cursorPointer}>{props.children}</div>
  if (settings.ABSOLUTE_URL && props.to !== '/' && !local) {
    const { to, ...p } = props;
    return <a href={`${settings.ABSOLUTE_URL}${to}`} {...p} />
  } else {
    return (
      <NavLink {...props} activeStyle={{ color: theme.activeLinkColor }} />
    )
  }
}

export default L;
